.container {
  width: 300px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex-shrink: 0;
  padding: 20px;
  border-right: 1px solid #cecece;
  word-wrap: break-word;
}

.container.hidden {
  width: fit-content;
}

.containerHeader {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
