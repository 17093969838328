.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 5;
  height: 100%;
}

.dndflow aside {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
  height:100%;
  box-sizing: border-box;
}

.repositoriesContainer {
  height: 45%;
  display: flex;
  flex-direction: column;
}

.dndflow aside .description {
  margin-bottom: 10px;
}

.dndnode {
  height: 30px;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
  background-color: #6cb8cf;
  color: white;
  font-size: medium;
}

.dndnode.input {
  border-color: #0041d0;
}

.addExternalContainer {
  margin: 10px 0;
  display: flex;
}

.externalContainer {
  overflow-y: auto;
}

.addButtonStyle {
  margin: 20px;
}

.dndnode.output {
  border-color: #ff0072;
}

.reactflowwrapper {
  flex-grow: 1;
  height: 100%;
  width: 100%;
}

.selectall {
  margin-top: 10px;
}

.contextMenu {
  background: white;
  border-style: solid;
  box-shadow: 10px 19px 20px rgba(0, 0, 0, 10%);
  position: absolute;
  z-index: 10;
}

.contextMenu button {
  border: none;
  display: block; 
  padding: 0.5em;
  text-align: left;
  width: 100%;
}

.contextMenu button:hover {
  background: var(--green);
}

@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }
}