.root {
  position: fixed;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background-color: var(--light-grey);
}

.container {
  max-width: 768px;
  width: 576px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 32px;
  background-color: white;
  border: 1px solid var(--light-grey);
  border-radius: 6px;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 8px 0;
  word-break: normal;
  overflow-wrap: anywhere;
}

.title {
  line-height: 1;
  margin: 0;
}

.description {
  font-size: 14px;
  color: var(--grey);
}

.note {
  font-size: 14px;
  color: var(--grey);
}

.buttons {
  display: flex;
  gap: 16px;
}

.buttons > button {
  flex: 1;
}

@media (max-width: 576px) {
  .root {
    background-color: white;
  }
  .container {
    width: 100%;
    padding: 16px;
    border: none;
  }
}
