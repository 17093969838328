.workspaceRoot {
  display: flex;
  margin: 123px 0px;
}

.workspaceBoxContainer {
  display: flex;
  flex-direction: column;
}

.workspaceBox {
  width: 367px;
  height: 562px;
  margin: 0px 30px;
  box-shadow: 10px 10px 30px 0 var(--blur-shadow-color);
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
}

.workspaceTitle {
  padding-left: 30px;
  margin-top: 41px;
  height: 46px;
}

.workspaceTitleSmall {
  margin: 0px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b7b7b9;
  transition: all 0.3s;
}

.workspaceTitleLarge {
  margin: 0px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.workspaceEditIcon {
  height: 14px;
  margin-left: 13px;
  cursor: pointer;
}

.workspaceHelpIcon {
  margin: 0 30px 0px auto;
  cursor: pointer;
}
.workspaceBoxContent {
  flex: 1;
  min-height: 0;
}
.workspaceTeamSettingsItem {
  margin-top: 30px;
  height: calc(100% - 30px);
  overflow-y: scroll;
}

.workspaceBoxContent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background-color: #f5f5f5;
}

.workspaceBoxContent::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.workspaceBoxContent::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #a9a9a9;
}

.workspaceTeamSettingsItem > .workspaceSettingsSubItems:not(:first-child) {
  margin: 20px 0;
}

.workspaceSettingsSubItems {
  padding: 0px 5px 0px 30px;
  align-items: center;
  display: flex;
}

.workspaceSettingsSubItems svg {
  margin-right: 8px;
}

.workspaceTeamMemberInfo {
  width: 100%;
}

.workspaceTeamMemberName {
  margin: 0;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.workspaceTeamMemberName span {
  color: #b7b7b9;
}

.workspaceTeamMemberEmail {
  display: flex;
  margin: 0;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b7b7b9;
  margin-bottom: 6px;
}

.workspaceTeamMemberEmail img {
  margin-left: auto;
  cursor: pointer;
}

.workspaceTeamMemberRole {
  font-family: Poppins;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2.4px;
  text-align: center;
  color: #ffffff;
  padding: 3px 10px;
  width: 38px;
  display: table-cell;
  vertical-align: middle;
  height: 14px;
  background-color: #8eb9a8;
}

.promoteBtn {
  padding: 5px;
}

.promotionContainer {
  width: 140px;
  height: 98px;
  background-color: #ffffff;
  outline: none;
  display: flex;
  flex-flow: column;
  padding: 0;
  box-shadow: 5px 5px 18px 0 rgba(0, 0, 0, 0.16);
}

.promoteGroup {
  outline: none;
  display: flex;
  justify-content: space-evenly;
}

.removeBtn {
  text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.16);
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  color: #f4828c;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: fit-content;
  padding: 5px 10px;
  margin: 0 auto;
  transition: all 0.3s;
}

.removeBtn:hover {
  box-shadow: 0 0 30px 0 rgba(244, 130, 140, 0.5);
  background-color: #ffffff;
}

.removeBtn img {
  margin-left: 3px;
  height: 20px;
  width: 20px;
}

.promotesuperuser {
  padding: 3px 10px;
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  height: fit-content;
  transition: all 0.3s;
  cursor: pointer;
  color: #eacda3;
  border: solid 2px #eacda3;
  margin-top: 20px;
}

.promotesuperuser:hover {
  color: #ffff;
  background-color: #eacda3;
  border: solid 2px #eacda3;
}

.promoteadmin {
  padding: 3px 10px;
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  height: fit-content;
  transition: all 0.3s;
  cursor: pointer;
  color: #365272;
  border: solid 2px #365272;
  margin: 20px 0px 15px;
}

.promoteadmin:hover {
  color: #ffff;
  background-color: #365272;
  border: solid 2px #365272;
}

.promoteuser {
  margin-top: 20px;
  padding: 3px 10px;
  color: #8eb9a8;
  border: solid 2px #8eb9a8;
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
  font-size: 8px;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  height: fit-content;
  transition: all 0.3s;
  cursor: pointer;
}

.promoteuser:hover {
  color: #ffff;
  background-color: #8eb9a8;
  border: solid 2px #8eb9a8;
}

.workspaceBoxFooter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.workspaceBoxFooter::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #a9a9a9;
}

.workspaceBoxFooter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background-color: #f5f5f5;
}

.workspaceBoxFooter::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}

.workspaceBoxFooter div:first-child {
  margin-top: 20px;
}
.workspaceBoxFooter div:last-child {
  margin-bottom: 20px;
}

.workspaceTeamAdd,
.workspaceTeamLeave,
.workspaceTeamSettings {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 310px;
  transform: translateX(10%);
  /* position: absolute;
  bottom: 23px;
  left: 50%; */
  padding: 10px 0px;
  border-top: rgba(183, 183, 185, 0.16) 1px solid;
  cursor: pointer;
  transition: all 0.3s;
}

.workspaceTeamAdd {
  bottom: 15px;
}

.workspaceTeamAdd:hover,
.workspaceTeamSettings:hover {
  box-shadow: 0 0 30px 0 rgba(54, 82, 114, 0.3);
}

.workspaceTeamLeave:hover {
  box-shadow: 0 0 30px 0 rgba(244, 130, 140, 0.7);
}

.workspaceTeamLeave p,
.workspaceTeamAdd p,
.workspaceTeamSettings p {
  text-shadow: 2px 2px 15px var(--blur-shadow-color);
  font-family: Poppins;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3px;
  text-align: right;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #365272;
}

.workspaceTeamLeave p {
  color: #f4828c;
}

.workspaceTeamLeave img,
.workspaceTeamAdd img,
.workspaceTeamSettings svg {
  margin-left: 9px;
  width: 36px;
  height: 20px;
}

.sidebarQuaternary {
  height: calc(100vh - 123px);
  /* margin-top: 123px; */
}

.workspaceProjects {
  height: calc(100vh - 123px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  scrollbar-width: none; /** For firefox*/
}

.workspaceProjects::-webkit-scrollbar-track {
  display: none;
}

.workspaceProjects::-webkit-scrollbar {
  display: none;
}

.workspaceProjects::-webkit-scrollbar-thumb {
  display: none;
}

.workspaceProjects > div:first-child {
  margin: 0;
  margin-bottom: 10px;
}

.workspaceProjects > div:not(:first-child) {
  margin: 10px 0;
}

.workspaceProjectCard {
  width: 232px;
  height: 53px;
  box-shadow: 7px 7px 25px 0 var(--blur-shadow-color);
  background-color: #ffffff;
  display: flex;
}

.workspaceProjectCardInfo {
  margin: 10px;
}

.workspaceProjectCardInfo h3 {
  margin: 0;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.workspaceProjectCardInfo p {
  display: flex;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b7b7b9;
}

.workspaceProjectCardInfo p img {
  margin-left: 6px;
}

.workspaceProjectCardSetting {
  cursor: pointer;
  margin: 0 10px 0px auto;
}

.itemsMenu {
  display: flex;
  align-items: center;
  width: 100%;
  transition: all 0.3s;
  background: none;
  padding: 15px 0px 15px 30px;
}

.itemsMenu span {
  color: var(--main-color);
  display: inline-flex;
  margin-right: 8px;
  font-size: 16px;
}

.itemsMenu:hover,
.itemsMenu.selected {
  background-color: #365272;
  cursor: pointer;
}

.itemsMenu:hover span,
.itemsMenu.selected span {
  color: white;
}

/* Token.tsx */
.TokenRoot {
  margin: 86px auto auto 30px;
  min-width: 515px;
  height: fit-content;
  background: white;
  padding: 12px 12px 8px;
}

.hide {
  display: none;
}

.tokenBox {
  margin-bottom: 10px;
  width: stretch;
  box-shadow: 5px 5px 18px 0 var(--blur-shadow-color);
  display: flex;
  justify-content: space-between;
  padding: 12px 8px;
}

.tokenLeftPanel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: space-evenly;
  min-width: 50%;
  padding: 8px 10px;
}

.nameBox {
  text-transform: capitalize;
  font-size: 1.05rem;
}

.statusBox {
  font-size: 10px;
  color: var(--grey);
}
.statusBox .good {
  color: #4ec85b;
}
.statusBox .unknown {
  color: var(--grey);
}
.statusBox .warn {
  color: #e5242d;
}

.tokenLeftPanel {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.textBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: stretch;
  margin-left: 30px;
}

.logoBox img {
  width: 50px;
  height: 50px;
}

.tokenActionPanel .button {
  display: block;
  border: none !important;
  width: 160px;
  height: 35px;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
  letter-spacing: 3px;
  margin: 15px 30px;
  color: #ffffff;
  font-size: 0.8em;
}

.button.addBtn {
  background-color: #8eb9a8;
}

.button.removeBtn {
  background-color: #f4828c;
}

.tokenActionPanel .button:hover,
.tokenActionPanel .button:active,
.tokenActionPanel .button:focus {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  color: #ffffff;
  border: none !important;
}

.button.removeBtn:hover,
.button.removeBtn:active,
.button.removeBtn:focus {
  background-color: #f4828c;
}

.button.addBtn:hover,
.button.addBtn:active,
.button.addBtn:focus {
  background-color: #719486;
}
