.container {
  width: 300px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  flex-shrink: 0;
  padding: 20px;
  border-left: 1px solid #cecece;
  word-wrap: break-word;
}

.container.hidden {
  width: fit-content;
}

.containerHeader {
  display: flex;
  gap: 10px;
  align-items: center;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.category {
  overflow-y: scroll;
}
