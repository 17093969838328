.parentDiv {
  box-sizing: border-box;
}

.errorIcon {
  display: inline;
  vertical-align: top;
}

.errorMessage {
  padding-left: 20px;
  display: inline;
  width: 90%;
}
