.root {
  display: flex;
  height: 100vh;
}

.sideBarPrimary {
  flex-basis: 85px;
}

.sidebarSecondaryContainer {
  display: flex;
  overflow-y: hidden;
  width: 100%;
  scrollbar-width: none; /** For firefox*/
}

.sidebarSecondaryContainer::-webkit-scrollbar-track {
  display: none;
}

.sidebarSecondaryContainer::-webkit-scrollbar {
  display: none;
}

.sidebarSecondaryContainer::-webkit-scrollbar-thumb {
  display: none;
}

.settingsName {
  margin: 80px 0 25px;
  font-family: Poppins;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 2.4px;
  text-align: center;
  vertical-align: middle;
  color: #365272;
  padding: 3px 16px;
  background-color: #ffffff;
}

.settingsOption {
  width: 85px;
  height: 85px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s;
}

.settingsOption:hover,
.settingsOption.selected {
  box-shadow: 5px 5px 15px 0 var(--blur-shadow-color);
  background-color: #ffffff;
  color: #365272;
}

.settingsOptionName {
  text-shadow: 2px 2px 15px var(--blur-shadow-color);
  font-family: Poppins;
  font-size: 8px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.6px;
}

.settingsOption:hover .settingsOptionLogo1,
.settingsOption.selected .settingsOptionLogo1 {
  background: url('/public/assets/logos/workspace-logo-click.svg') no-repeat;
  background-position: center;
  transition: all 0.3s;
}

.settingsOption:hover .settingsOptionLogo2,
.settingsOption.selected .settingsOptionLogo2 {
  background: url('/public/assets/logos/project-logo-click.svg') no-repeat;
  background-position: center;
  transition: all 0.3s;
}

.settingsOption:hover .settingsOptionLogo3,
.settingsOption.selected .settingsOptionLogo3 {
  background: url('/public/assets/logos/user-logo-click.svg') no-repeat;
  background-position: center;
  transition: all 0.3s;
}

.settingsOptionLogo1 {
  background: url('/public/assets/logos/workspace-logo.svg');
  height: 36.8px;
  width: 67.8px;
  background-position: center;
}

.settingsOptionLogo2 {
  background: url('/public/assets/logos/project-logo.svg');
  margin-bottom: 2px;
  height: 36.8px;
  width: 67.8px;
  background-position: center;
}

.settingsOptionLogo3 {
  background: url('/public/assets/logos/user-logo.svg');
  margin-bottom: 2px;
  height: 36.8px;
  width: 67.8px;
  background-position: center;
}
