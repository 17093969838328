.root {
  margin: 121px 0px 0px 30px;
  display: flex;
}

.general {
  padding: 30px;
  width: 455px;
  /* height: 480px; */
  height: fit-content;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.userAvatarContainer {
  width: 455px;
  height: 190px;
  padding: 30px 0;
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.userAvatar {
  margin: 0px 45px;
  display: flex;
  align-items: center;
}

.userAvatar > svg {
  width: 138px;
  height: 138px;
}

.userAvatar button {
  padding: 12px 5px 0px;
  font-size: 15px;
  font-weight: 500;
}

.userAvatar button img {
  margin-right: 17.5px;
}

.userAvatar button:first-child {
  margin-left: 51px;
  color: #8eb9a8;
}

.userAvatar button:last-child {
  margin-left: 46px;
  color: #365272;
}

.userName {
  margin: 10px 0px;
}

.userName > div {
  width: 182px;
}

.userName > div:first-child {
  margin: 0px 30px;
}

.userPasswordContainer {
  margin-top: 30px;
  width: 455px;
  height: fit-content;
  /* height: 201px; */
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.userPasswordContainer > div {
  width: 395px;
  margin: 10px 30px 5px;
}

.userPasswordContainer > div:last-child {
  margin-bottom: 10px;
}

.deleteAccountContainer {
  margin-top: 30px;
  padding: 10px 0;
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: white;
  cursor: pointer;
  color: #f4828c;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
}
.deleteAccountContainer:hover {
  background-color: #f4828c;
  color: white;
}

.submitBtnContainer {
  margin-top: 10px;
}
