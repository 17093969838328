.container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 24px;
  background-color: #f4f4f4;
  font-family: 'Poppins', sans-serif;
  overflow-y: scroll;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headerTitle {
  margin: 0;
  font-weight: 400;
  font-size: 2.1rem;
  color: var(--main-color);
}

.headerSubtitle {
  margin-top: 0px;
  font-weight: 400;
  font-size: 1.7rem;
}

.impactTitle {
  font-weight: 500;
  font-size: 1.3rem;
  margin-bottom: 13px;
  margin-top: 13px;
  color: var(--main-color);
}

.infoCard {
  position: relative;
  background-color: white;
  border: 1px solid var(--main-color);
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
  border-radius: 8px;
  padding: 20px;
  font-weight: 400;
}

.infoCardHead {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: default;
  overflow: hidden;
}

.infoCardHead.hoverable {
  cursor: pointer;
}

.infoCardHead.hoverable:hover .packageName,
.infoCardHead.hoverable:hover .expandIcon {
  transform: scale(1.03);
}

.expandIcon {
  font-size: 1.27rem;
  color: #477ab5;
  margin-top: 3px;
  flex-shrink: 0;
}

.packageName {
  font-size: 1.5rem;
  color: #477ab5;
  margin: 0;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.packageDetails p {
  margin: 5px 0;
}

.directlyImpacted {
  margin-top: 15px;
}

.fileInfo {
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
}

.fileInfo p {
  margin: 3px 0;
}

.graphViewButton {
  display: inline-block;
  text-decoration: none;
  background-color: var(--main-color);
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 0.9rem;
  line-height: 1.2;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.graphViewButton:hover {
  background-color: #477ab5;
}

.checkboxGroup {
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkboxGroup label {
  display: flex;
  align-items: center;
  gap: 5px;
}

.checkboxGroup span {
  font-weight: 500;
}

.checkboxGroup input[type='checkbox'] {
  accent-color: var(--main-color);
}

.noSecurityIssues {
  width: 100%;
  flex: 1;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emoji {
  font-size: 128px;
}

@media only screen and (max-width: 1100px) {
  .container {
    padding: 12px;
  }

  .header {
    flex-direction: column;
    align-items: flex-start;
  }

  .headerTitle {
    font-size: 1.5rem;
  }

  .headerSubtitle {
    font-size: 1.2rem;
  }

  .checkboxGroup {
    flex-direction: column;
    align-items: flex-start;
  }
}
