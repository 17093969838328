/* Title */
.reportTitleStyle {
  font-weight: 300;
  font-size: 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.titleStyles {
  width: 90%;
  overflow-wrap: break-word;
}

.shareBtn {
  margin: 5px;
  padding: 0;
  color: var(--light-grey);
  font-size: 30;
  z-index: 100;
  transition: linear 0.1s;
}
.shareBtn:hover {
  color: var(--main-color);
}

/* Info table */
.reportTable {
  margin: 36px 0 42px 0;
}

.reportRow {
  display: grid;
  grid-template-columns: 18% 32% 18% 32%;
  border-bottom: 1px solid var(--light-grey);
  font-size: 14px;
}
.reportRow:first-child {
  border-top: 1px solid var(--light-grey);
}
.reportRow > div {
  padding: 16px 0;
}

/* Session */
.graphContainer {
  margin-bottom: 50px;
}

/* Each session */
.titleContainerFlexStyle {
  padding: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.titleContainerFlexStyle > *:nth-child(2) {
  flex: 1;
}
.iconHolder {
  width: 10%;
  text-align: right;
}
.generalTitleText {
  color: var(--main-color);
  font-weight: 500;
  border-bottom: 4px solid;
}
.generalIconHolder {
  display: inline-block;
  background-color: var(--main-color);
  padding: 8px 8px 3px;
  width: fit-content;
  height: fit-content;
}
.titleImage {
  position: relative;
  top: 2px;
}
.generalTitleHolder {
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--main-color);
  font-weight: 500;
  padding-left: 10px;
  border-bottom-color: var(--main-color);
}

.titleIcon {
  color: white;
}

.dependencyTableTitle {
  margin-bottom: 10px;
  color: var(--blue);
}

.dependencyTable {
  margin-top: 30px;
}

/* Graph session */
.sessionContent {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px;
}
.img {
  max-width: 100%;
  display: block;
  margin: auto;
  max-height: 1000px;
}

/* level buttons group */
.enabledLevelBtn {
  background-color: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
  display: inline-block !important;
  color: white !important;
}
.disabledLevelBtn {
  background-color: var(--light-grey) !important;
  border: 1px solid var(--light-grey) !important;

  display: inline-block !important;
  color: white !important;
}
.leftBtn {
  padding: 5px 15px 5px 11px;
  border-radius: 0 !important;
}
.rightBtn {
  padding: 5px 11px 5px 15px;
  border-radius: 0 !important;
}
.levelBtnTextHolder {
  padding: 5px 17px;
  display: inline-block;
  text-align: center;
  border: 1px solid transparent !important;
}
.levelBtnText {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 15;
  color: var(--main-color);
}
.levelBtnHolder {
  border: 4px solid;
  border-color: var(--main-color);
  margin: auto;
  width: fit-content;
  border-radius: 0;
}
/* Rule violations session */
.violationList {
  width: 100%;
  max-width: 100%;
  height: auto;
  list-style: none;
}

.violationList li {
  overflow-wrap: break-word;
  margin-bottom: 20px;
}

.notFixedViolation {
  position: relative;
}
.notFixedViolation::before {
  background-color: #d8152b;
}

.fixedViolation {
  position: relative;
}
.fixedViolation::before {
  background-color: #1fafa1;
}

.notFixedViolation::before,
.fixedViolation::before {
  content: '';
  display: block;
  position: absolute;
  width: 6px;
  border-radius: 8px 0 0 8px;
  top: -1px;
  left: -1px;
  bottom: -1px;
}

.violationIcon {
  position: absolute;
  margin: 18px;
  margin-left: 24px;
}

.violationText {
  padding: 10px;
  margin-left: 50px;
}

/* Insight session */

/* Code duplicate session */
.duplicateItem {
  margin: 10px 0;
}
.codeBlock {
  display: block;
  margin: auto;
  max-height: 50vh;
  max-width: 100vw;
  background-color: #f6f8fa;
  overflow: auto;
}
.highlighter {
  color: var(--blue);
  font-weight: 600;
}
.infoIcon {
  display: inline-block;
  padding: 2px 10px;
  background-image: radial-gradient(#738eaa, #7b92a8);
  border: solid 0.5px #7c95ab;
  border-radius: 10%;
  color: white;
}

@media only screen and (max-width: 550px) {
  .reportTitleCell {
    width: 40%;
  }
  .reportContentCell {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .violationList {
    padding-left: 0;
  }
  .reportRow {
    grid-template-columns: 40% 60%;
    border-bottom: none;
  }
  .reportRow:first-child {
    border-top: none;
  }
  .reportRow > div {
    border-bottom: 1px solid var(--light-grey);
  }
  .reportRow:first-child > div:nth-child(1),
  .reportRow:first-child > div:nth-child(2) {
    border-top: 1px solid var(--light-grey);
  }
}

/*************************************************************************************
**************************************************************************************
******           **********     ****   **********        **************  *************
******   ******************      ***   **********   **    *************  *************
******   ******************   *   **   **********   ***    ************  *************
******           **********   *    *   **********   ****    ***********  *************
******   ******************   **   *   **********   ***    ************  *************
******   ******************   ***      **********   **    ****************************
******           **********   ****     **********        **************  *************
**************************************************************************************
**************************************************************************************/
