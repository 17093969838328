.root {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  transition: all 1s;
  padding: 24px;
}

/* Only from 1420px width */
.graphControllerLargeScreen {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.controller {
  padding: 5px;
  margin: 10px 8px;
  color: var(--main-color);
  border-radius: 8px;
  border: var(--main-color) 1px solid;
  text-transform: capitalize;
}
.controller:hover,
.controller.selected {
  color: #ffffff;
  background-color: var(--main-color);
}

.graphControllerSmallScreen {
  display: none;
}
.selector {
  color: var(--main-color) !important;
}
.selector select {
  padding: 10px !important;
  font-size: 14px;
}
.selectControl {
  width: 50%;
  max-width: 300px;
  min-width: 100px;
  margin: auto !important;
  display: block !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.selectControl div {
  width: 100%;
}
.customSelect {
  text-transform: capitalize;
  color: var(--main-color);
}

.chartWrapper {
  flex: 1;
  width: 80%;
  margin: 0 auto;
  height: 80vh;
  overflow: auto;
}

.filterWrapper {
  margin: auto;
  max-width: 80%;
  min-width: 40%;
  height: 40px;
}

.popOverStyle {
  font-size: 12px;
  padding: 10px;
  font-weight: 300;
}

.popOverHeading {
  font-weight: 300;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .root {
    padding: 16px;
    gap: 16px;
  }
}

/* width <= 1419px */
@media only screen and (max-width: 1419px) {
  .graphControllerLargeScreen {
    display: none;
  }
  .graphControllerSmallScreen {
    display: block;
  }
  .chartWrapper {
    width: 100%;
    margin: auto;
  }
  .selectControl {
    width: 100%;
  }
}
