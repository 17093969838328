/* REPOSITORIES */
.repositories {
  overflow-y: scroll;
  scrollbar-width: none; /** For firefox*/
}

.repositories::-webkit-scrollbar {
  display: none;
}

.repositories > div:not(:first-child) {
  margin: 30px 0;
}

.repository {
  border-radius: 6px;
  max-width: 429px;
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  display: flex;
  border: solid 2px #ffffff;
  transition: all 0.3s;
}

.repository:focus,
.repository:hover {
  box-shadow: 0 0 30px 0 rgba(0, 89, 190, 0.4);
  border: solid 2px var(--main-color);
  cursor: pointer;
}

.repositoryLogo {
  margin: 13px;
  width: 26.7px;
  height: 26.7px;
}

.repositoryInfo {
  padding: 7px 0;
}

.repositoryName {
  font-family: Poppins;
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.repositoryStatus {
  font-family: Poppins;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgb(158, 158, 158);
}

.repositoryDeleteBtn {
  margin-right: 13px;
  margin-left: auto;
  margin: auto 13px auto auto !important;
  color: #000000;
}

.repositoryDeleteBtn:hover:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}
.repositoryDeleteBtn.disabled:hover {
  cursor: default;
  background-color: #ffffff;
}
.repositoryDeleteBtn.disabled {
  color: rgb(97, 96, 96);
  background-color: #ffffff;
}

.addRepository {
  border-radius: 6px;
  height: 53px;
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: rgb(142, 185, 168);
  display: flex;
}

.addRepository.disabled {
  background-color: rgba(142, 185, 168, 0.5);
}

.addRepository:hover:not(.disabled) {
  cursor: pointer;
  background-color: rgb(110, 168, 140);
}

.addRepository p {
  font-family: Poppins;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.addRepository img {
  width: 23.3px;
  height: 23.3px;
}

.addRepository > * {
  margin: 15px 0px 15px 15px;
}

/* Submission Buttons */
.submissionBtns {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.submissionBtns > button {
  width: 112px;
  height: 35px;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3px;
  border: none;
  display: flex;
  align-items: center;
  margin: 15px 30px;
  cursor: pointer;
  transition: all 0.3s;
}

.submissionBtns .saveBtn {
  color: #8eb9a8;
}

.submissionBtns .discardBtn {
  color: #f4828c;
}

.submissionBtns span {
  width: 15px;
  height: 15px;
  margin: 10px;
  transition: all 0.3s;
}

.submissionBtns > button:hover {
  box-shadow: 0 0 15px 0 #ffffff;
  color: #ffffff;
}

.submissionBtns > .discardBtn:hover {
  background-color: #f4828c;
}

.submissionBtns > .saveBtn:hover {
  background-color: #8eb9a8;
}

.submissionBtns .saveBtn span {
  background-image: url('/public/assets/icons/success-small-icon.svg');
}

.submissionBtns .discardBtn span {
  background-image: url('/public/assets/icons/fail-small-icon.svg');
}

.submissionBtns .saveBtn:hover span {
  background-image: url('/public/assets/icons/success-small-icon-hover.svg');
}

.submissionBtns .discardBtn:hover span {
  background-image: url('/public/assets/icons/fail-small-icon-hover.svg');
}
