.node {
  background-color: var(--external-service-color);
  padding: 6px 10px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  cursor: grab;
  color: white;
  font-size: medium;
}

.node:hover {
  cursor: pointer;
  text-decoration: underline;
}

.repositoryLogo {
  margin: 13px;
  width: 26.7px;
  height: 26.7px;
}

.drag {
  color: var(--mui-grey);
}

.drag:hover {
  cursor: grab;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 13px;
}

.path {
  font-size: 12px;
  opacity: 50%;
}

.highlight {
  box-shadow:  0 2px 5px 10px rgba(114, 111, 111, 0.6);
}