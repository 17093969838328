.root {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  font-size: 20px;
  padding: 32px;
}

.loadingTitle {
  font-size: 24px;
}

.link {
  color: var(--main-color);
}

@media (max-width: 320px) {
  .root {
    font-size: 16px;
  }
}
