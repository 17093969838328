.card {
  width: 100%;
  height: stretch;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.mainContent {
  margin: 10px;
  text-align: center;
}

.media {
  margin: auto;
  width: 80%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.mediaTitle {
  text-align: center;
  text-shadow: 0 1px #808d93, -1px 0 #cdd2d5, -1px 2px #808d93, -2px 1px #cdd2d5,
    -2px 3px #808d93, -3px 2px #cdd2d5, -3px 4px #808d93, -4px 3px #cdd2d5,
    -4px 5px #808d93, -5px 4px #cdd2d5, -5px 6px #808d93, -6px 5px #cdd2d5,
    -6px 7px #808d93, -7px 6px #cdd2d5, -7px 8px #808d93, -8px 7px #cdd2d5;
  font-weight: 600;
  font-stretch: ultra-expanded;
  word-spacing: 3px;
  font-size: 6vw;
  height: fit-content;
}

@-moz-document url-prefix() {
  .card {
    height: max-content;
  }
}
