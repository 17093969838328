.root {
  display: flex;
  align-items: flex-start;
  margin: 192px 48px;
  gap: 16px;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
  width: 512px;
  max-height: 100%;
  padding: 32px;
  background-color: white;
  overflow-y: auto;
}

.container.full {
  height: 100%;
}

.loading {
  flex: 1;
  display: grid;
  place-items: center;
}

.error {
  flex: 1;
  display: flex;
  gap: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error > svg {
  font-size: 64px;
}

.label {
  margin: 0;
  font-weight: 400;
  font-size: 22px;
}

.feature {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.featureLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}

.featureOptions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media screen and (max-width: 768px) {
  .root {
    margin: 0;
    flex: 1;
  }

  .container {
    width: 100%;
    height: 100%;
    padding: 24px;
    border: none;
    border-radius: 0;
  }
}
