.outer[data-disabled='true'] {
  opacity: 0.6;
}

.outer[data-disabled='true']:hover {
  cursor: default !important;
}

.container {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px;
  border: 1px solid var(--mui-grey);
  user-select: none;
}

.label {
  font-size: 13px;
  margin-bottom: 5px;
}
