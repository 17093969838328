.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 16px;
}

.buttons {
  display: flex;
  gap: 16px;
}

.modelName {
  font-weight: 600;
}
