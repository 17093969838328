/* Container */
.root {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 0;
}

.container {
  width: fit-content;
  position: relative;
  display: flex;
  z-index: 1;
  margin: auto;
  height: 650px;
  box-shadow: 10px 10px 40px 0 var(--blur-shadow-color);
  border: none !important;
}

.leftContainer {
  display: inline-flex;
  position: relative;
  width: 500px;
  height: -webkit-fill-available;
  background-image: url('/public/assets/images/form-cover.svg');
}

.rightContainer {
  display: inline-flex;
  position: relative;
  width: 500px;
  height: -webkit-fill-available;
  box-shadow: 10px 10px 40px 0 var(--blur-shadow-color);
}

/* Right container */
.rightContent {
  z-index: 1000;
  width: 500px;
  height: -webkit-fill-available;
  background-color: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

/* Form */
.authFormContainer {
  width: stretch;
  margin: auto;
  padding: 0 48px;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
  justify-content: center;
  box-sizing: border-box;
}

.formFieldContainer {
  margin-bottom: 4px;
}

.checkbox {
  padding: 0px !important;
}

.checkboxLabel {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: var(--grey);
  text-align: start;
}

.formBox {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
}
.formBoxCenter {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.opensourceContainer {
  display: flex;
  flex-direction: column;
  width: stretch;
  height: 100%;
  padding: 24px 48px;
  justify-content: center;
  box-sizing: border-box;
}

.opensourceLogoContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.opensourceLogo {
  display: none;
}

.opensourceBottomContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.opensourceLabel {
  font-weight: 500;
  text-align: center;
}

.opensourceFormContainer {
  display: flex;
  flex-direction: column;
  gap: 48px;
  box-sizing: border-box;
}

.opensourceContentContainer {
  display: flex;
  gap: 24px;
  flex-direction: column;
}

.opensourceInputContainer {
  display: flex;
  gap: 4px;
  flex-direction: column;
}

.rememberMeContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.link {
  font-size: 12px;
  color: var(--main-color);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.oauthBtnContainer {
  min-height: 20%;
  display: flex !important;
  gap: 24px;
  flex-direction: column;
  margin-top: 24px;
}

.oauthBtnContainer.dark {
  padding: 50px 0 60px;
}

.oauthDivider {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--grey);
  font-weight: 600;
  text-align: center;
  user-select: none;
}

.oauthDivider hr {
  position: absolute;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--grey);
}

.oauthDivider span {
  padding: 0 16px 0 16px;
  background-color: white;
  z-index: 100;
}

.anchorText {
  color: #42bff2;
  text-decoration: none;
}

.anchorLink {
  color: var(--main-color);
  font-size: 12px;
  text-decoration: none !important;
}

.errorDisplay {
  color: red;
  text-align: center;
  line-height: 1;
}

.envText {
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}

.hr {
  width: 20%;
  border: 1px solid white;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
}

.goBackBtn {
  position: absolute;
  top: 56px;
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 1100px) {
  /* Container */
  .container {
    width: 1000px;
  }

  .leftContainer {
    flex-direction: column;
  }
  .leftContentBox {
    opacity: 1;
    width: 100%;
    z-index: 1000;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .logoContainer {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .logoContainer img {
    display: block;
    max-width: 50%;
  }
  .envText {
    color: white;
  }
  /* Right container */
  .rightContainer {
    z-index: 100;
    position: absolute;
    right: 0;
  }
  .envContainer {
    display: none;
  }
  .oauthBtnContainer:not(.dark) {
    display: none;
  }
}

@media only screen and (max-width: 1099px) {
  .leftContainer {
    display: none;
  }
  .rightContainer {
    display: flex;
  }
  .formBox {
    margin-top: 0;
    margin-bottom: 0;
  }
  .hr {
    border: 1px solid #e5262f;
  }
  .envContainer {
    display: flex;
    height: 20%;
    align-items: center;
    justify-content: center;
  }
  .authFormContainer {
    padding: 32px 48px;
  }
  .opensourceLogo {
    display: block;
  }
}

@media only screen and (max-width: 550px) {
  .container,
  .rightContainer {
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
  .authFormContainer {
    padding: 32px 24px;
  }
  .rightContent {
    width: 100%;
  }
}

/* For firefox */
@-moz-document url-prefix() {
  .leftContainer,
  .rightContainer,
  .rightContent,
  .authFormContainer {
    height: 100%;
  }
}
