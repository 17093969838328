.integrationTitle {
  display: flex;
  align-items: center;
}

.integrationTitle p {
  margin: 14px 0px 0px 30px;
  padding-right: 10px;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  border-right: 1px solid rgba(0, 0, 0, 0.16);
}

.integrationTitle span {
  font-size: 15px;
  font-weight: 500;
}

.integrationRoot {
  margin: 86px auto auto 30px;
  min-width: 515px;
  min-height: 735px;
}

.integrationSSH {
  display: flex;
}

.integrationSSHBody {
  width: 515px;
  height: 164px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.integrationVCS {
  margin-top: 30px;
  width: 515px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.integrationTitle p,
.integrationTitle svg {
  margin-top: 30px;
}

.integrationTitle span {
  margin: 30px 9px 0px 11px;
}

.integrationSSHInput {
  width: 455px;
  min-height: 56px;
  margin: 20px 30px 30px !important;
}

/* Set textarea outside maxheight to be 60px */

.integrationSSHInput textarea {
  overflow: auto;
  max-height: 60px;
  overflow-y: scroll !important;
  scrollbar-width: none; /** For firefox*/
}

.integrationSSHInput textarea::-webkit-scrollbar {
  display: none;
}

.integrationSSHInput > div {
  padding: 7px 14px !important;
}

.integrationForm {
  display: flex;
}

.integrationVCS h2,
.integrationInfo h4 {
  font-size: 20px;
  font-weight: 300;
  margin: 0px;
}

.integrationVCS h2 {
  margin-top: 30px;
  margin-left: 30px;
}

.integrationVCS > .integrationConfigContainer:last-child {
  margin-bottom: 30px;
}

.integrationConfigContainer {
  width: 455px;
  min-height: 71px;
  margin: 18px 30px 0px;
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  transition: all 0.3s;
}

.integrationConfigContainer:hover {
  box-shadow: 0 0 30px 0 rgba(0, 89, 190, 0.4);
}

.integrationConfig {
  display: flex;
  align-items: center;
  height: 71px;
  cursor: pointer;
}

.integrationConfigContainer img {
  margin: 0px 14px 0px 18px;
  height: 43px;
  width: 43px;
}

.integrationInfo p {
  font-size: 10px;
  font-weight: 500;
  color: #b7b7b9;
}

.integrationConfig button {
  margin-left: auto;
}

.integrationInputContainer .integrationInput:last-child {
  margin-bottom: 14px;
}

.integrationInputContainer .MuiInputLabel-formControl {
  top: 7px;
}

.integrationInput {
  background-color: transparent;
  width: 251px;
  margin-top: 21px;
  margin-left: 16px !important;
}

.integrationInput div:before,
.integrationInput div:after {
  border-bottom-color: transparent;
  max-width: 321px !important;
  width: 321px;
}
.integrationInput:hover div:before,
.integrationInput:hover div:after {
  border-bottom-color: #b7b7b9;
  max-width: 321px !important;
  width: 321px;
}
