.container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.header {
  max-width: 256px;
  padding: 40px;
}

.logo {
  max-width: 256px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}

.content {
  position: relative;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 100px 48px 48px 48px;
  margin-top: auto;
  margin-bottom: 16px;
  box-sizing: border-box;
}

.large404 {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;
}

.label {
  font-size: 32px;
  font-weight: 900;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 16px;
  margin-top: 36px;
}

.waves {
  width: 100%;
  min-width: 1920px;
  display: flex;
  flex-direction: column;
}

.waves > svg {
  flex: 1;
  min-height: 384px;
}

@media screen and (max-width: 1440px) {
  .waves {
    max-height: 288px;
  }
}

@media screen and (max-width: 1280px) and (max-height: 720px) {
  .content {
    margin-bottom: 0px;
  }

  .waves {
    max-height: 256px;
  }
}

@media screen and (max-width: 810px) {
  .waves {
    max-height: 376px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    max-width: 50%;
    padding: 32px;
  }

  .label {
    font-size: 28px;
  }

  .content {
    padding: 88px 48px 0px 48px;
    margin-bottom: 0px;
  }

  .large404 {
    padding: 32px;
    box-sizing: border-box;
  }

  .buttons {
    flex-direction: column;
    gap: 8px;
    margin-top: 18px;
  }

  .waves {
    max-height: 304px;
  }
}

@media screen and (max-width: 768px) and (min-height: 900px) {
  .content {
    margin-bottom: 32px;
  }
}
@media screen and (max-width: 768px) and (min-height: 1024px) {
  .content {
    margin-bottom: 64px;
  }
}

/* This is last so that it overrides the previous media queries */
@media screen and (min-width: 720px) and (min-height: 1280px) and (orientation: portrait) {
  .content {
    margin: auto 0px;
  }
}
