.content {
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 0;
  box-sizing: border-box;
  overflow: -moz-scrollbars-none;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.mobileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backButtonContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  color: var(--main-color);
  text-decoration: none;
  user-select: none;
}

.filterButtonContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  color: var(--main-color);
  user-select: none;
}
