.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 10px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.4s ease, color 0.4s ease;
}

.button:focus-visible {
  transition: none;
  outline: var(--main-color) solid 2px;
  outline-offset: 2px;
}

.icon {
  width: 24px;
  height: 24px;
}

.primary {
  background-color: var(--main-color);
  color: white;
}

.primary:hover {
  background-color: var(--main-color-hover);
}

.secondary {
  background-color: transparent;
  color: var(--main-color);
}

.secondary:hover {
  background-color: var(--white-hover);
}

.outline {
  background-color: transparent;
  color: var(--main-color);
  border: solid 2px var(--main-color);
  padding: 8px;
}

.outline:hover {
  background-color: var(--main-color);
  color: white;
}

.primary.black {
  background-color: var(--black);
}

.primary.black:hover {
  background-color: var(--black-hover);
}

.primary.github {
  background-color: var(--github-color);
}

.primary.github:hover {
  background-color: var(--github-color-hover);
}

.primary.azure {
  background-color: var(--azure-color);
}

.primary.azure:hover {
  background-color: var(--azure-color-hover);
}

.secondary.black {
  color: var(--black);
}

.secondary.github {
  color: var(--github-color);
}

.secondary.azure {
  color: var(--azure-color);
}

.outline.black {
  border: solid 2px var(--black);
  color: var(--black);
}

.outline.black:hover {
  background-color: var(--black);
  color: white;
}

.outline.github {
  border: solid 2px var(--github-color);
  color: var(--github-color);
}

.outline.github:hover {
  background-color: var(--github-color);
  color: white;
}

.outline.azure {
  border: solid 2px var(--azure-color);
  color: var(--azure-color);
}

.outline.azure:hover {
  background-color: var(--azure-color);
  color: white;
}

.capitalize {
  text-transform: capitalize;
}

.fullWidth {
  width: 100%;
}

.disabled {
  cursor: not-allowed;
}

.disabled.primary,
.disabled.primary:hover {
  background-color: var(--main-color-disabled);
}

.disabled.secondary,
.disabled.secondary:hover {
  color: var(--main-color-disabled);
  background-color: transparent;
}

.disabled.outline,
.disabled.outline:hover {
  border: solid 2px var(--main-color-disabled);
  color: var(--main-color-disabled);
  background-color: transparent;
}

.left {
  justify-content: flex-start;
}

.right {
  justify-content: flex-end;
}

.center {
  justify-content: center;
}

.link {
  text-decoration: none;
}
