.subBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.contentBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}

.contentBody::-webkit-scrollbar {
  display: none;
}

.navigationBar {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: auto;
  justify-content: space-between;
  align-items: center;
  padding: 15px 15px 30px;
}

.navigationBar div {
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.backBtn {
  width: auto !important;
  justify-content: flex-start !important;
  padding: 5px 12px;
  transition: linear 0.1s;
}
.backBtn span {
  font-weight: 500;
  color: var(--grey);
  margin: 0px 3px;
  font-size: 25px;
}
.backBtn:hover {
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
  cursor: pointer;
}
.backBtn:hover span {
  color: var(--main-color);
}

.goForward span {
  padding: 10px 20px 10px 22px;
  font-size: 26px;
  color: white;
  background-color: var(--main-color);
  border-radius: 50%;
  border: none;
  transition: linear all 1s;
}
.goForward span.disabled {
  background-color: var(--light-grey) !important;
}
.goForward span:hover:not(.disabled) {
  cursor: pointer;
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
}

/* Table layout */
.tableLayout {
  margin-top: 60px;
  width: 100%;
}

/*  Unused or will be used in future?
.tableTitleRow {
} 
.tableSubtitleRow {
}
.tableContentRow {
}
*/

.tableTitleLogo {
  display: flex;
  justify-content: flex-end;
  border-right: 2px solid var(--light-grey);
  padding: 4px 20px 4px 0px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 10px;
  line-height: 1.5;
  align-items: center;
}

.tableTitleLogo img {
  width: 27px;
  margin-right: 10px;
}

.tableTitle {
  font-size: 25px;
  font-weight: 300;
  width: stretch;
  padding-left: 20px;
}

.tableSubtitle {
  font-size: 10px;
  font-weight: 500;
  padding: 0 20px;
}

.tableContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

@-moz-document url-prefix() {
  .tableTitle {
    width: 95%;
  }
}
