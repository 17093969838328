.thanks {
  font-size: 16px;
}

.package {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: #f2f2f2af;
}
.package:hover {
  background-color: #f2f2f2bf;
}

.packageHeader {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
  outline: none;
  border-radius: 6px;
}
.packageHeader:focus {
  outline: 2px solid var(--main-color);
  outline-offset: 2px;
}

.packageName {
  font-size: 16px;
  font-weight: 500;
}

.packageLicense {
  font-size: 14px;
  font-weight: 600;
  color: #989898;
}

.packageDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
  white-space: pre-wrap;
  padding: 16px;
}

@media (max-width: 1024px) {
  .packageLicense {
    display: none;
  }
}
