.waves {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-width: 1920px;
  display: flex;
  flex-direction: column;
}

.waves > svg {
  flex: 1;
  min-height: 384px;
}

@media screen and (max-width: 1440px) {
  .waves {
    max-height: 288px;
  }
}

@media screen and (max-width: 1280px) and (max-height: 720px) {
  .waves {
    max-height: 256px;
  }
}

@media screen and (max-width: 810px) {
  .waves {
    max-height: 376px;
  }
}

@media screen and (max-width: 768px) {
  .waves {
    max-height: 304px;
  }
}
