.root {
  box-sizing: border-box;
  flex: 1;
  border: 1px solid var(--light-grey);
  border-radius: 6px;
  height: 100%;
  overflow: auto;
  font-size: 14px;
}

.table {
  position: relative;
  min-width: 100%;
}
