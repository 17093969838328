.resizable {
  position: relative;
  height: 100% !important;
  flex-shrink: 0;
  border-right: 1px solid var(--light-grey);
}

.handle {
  position: absolute;
  width: 8px;
  height: 100%;
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  cursor: col-resize;
  transition: ease-in-out 0.1s;
}
.handle:hover {
  background-color: var(--mui-grey);
}
.handle:active {
  background-color: var(--blue);
}
