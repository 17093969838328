.cloud {
  margin: 189px 0px 0px 30px;
}

.info {
  width: 536px;
  height: 130px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
}

.plan {
  margin-top: 30px;
  width: 536px;
  height: 284px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.discardBtn {
  width: 112px;
  height: 35px;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.16);
  padding: 0;
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3px;
  border: none;
  display: flex;
  align-items: center;
  margin: 30px 0px 0px auto;
  cursor: pointer;
  transition: all 0.3s;
  color: #f4828c;
}

.discardBtn span {
  width: 15px;
  height: 15px;
  margin: 10px;
  transition: all 0.3s;
}

.discardBtn:hover {
  background-color: #f4828c;
  box-shadow: 0 0 15px 0 #ffffff;
  color: #ffffff;
}

.discardBtn:hover span {
  background-image: url('/public/assets/icons/fail-small-icon-hover.svg');
}

.discardBtn span {
  background-image: url('/public/assets/icons/fail-small-icon.svg');
}

.holder {
  width: 230px;
  margin-top: 30px;
}

.workspace {
  width: 170px;
}

.workspace label {
  font-size: 10px;
  font-weight: 500;
  color: #b7b7b9;
  text-align: center;
  margin: 0px 60px;
}

.workspace > div {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  height: 80px;
}

.workspace > div > div {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  padding: 21px 0px 15px !important;
  background-size: 0%;
}

.workspace > div::before,
.workspace > div::after,
.workspace:hover > div::before {
  border-bottom: none !important;
}

.workspace.MuiInputLabel-shrink {
  transform: translate(0, 1.5px);
}

.workspace .MuiSelect-select.MuiSelect-select {
  text-align: center;
}

.workspace svg {
  position: initial;
  margin: -20px;
}

.holder > * {
  margin: 0px;
  text-align: center;
}

.holder p,
.workspace p {
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: #b7b7b9;
}

.workspace p {
  transform: translateY(32px);
}

.holder h2 {
  font-size: 20px;
  font-weight: 500;
  color: #000000;
}

.holder h4 {
  font-size: 20px;
  font-weight: 300;
  color: #000000;
}

.seatInfo {
  display: flex;
}

.seatUsage {
  margin: 30px 75px 0px 30px;
}

.seatUsage > div {
  text-align: right;
}

.seatUsage span {
  font-size: 10px;
  font-weight: 500;
  text-align: right;
  color: #b7b7b9;
}

.seatUsage p {
  font-size: 20px;
  font-weight: 300;
  text-align: right;
  color: #000000;
  margin-top: -6px;
}

.seatPrice {
  margin-top: 46px;
}

.seatPrice h1 {
  font-size: 70px;
  font-weight: 200;
  color: #8eb9a8;
  text-align: center;
  margin: 0;
}

.seatPrice span {
  font-size: 13px;
  font-weight: 500;
}

.seats p {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #b7b7b9;
  margin-top: 1em;
}

.seatPrice p {
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #b7b7b9;
  margin-top: -6px;
}

.btnGroup {
  display: flex;
  border-top: 1px solid #b7b7b934;
  margin: 30px;
  padding-top: 20px;
}

.btnCancel,
.btnUpdateCard,
.btnUpgrade {
  width: 186px;
  height: 35px;
  border: none;
  outline: none;
  transition: all 0.3s;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btnCancel {
  background-color: transparent;
  color: #f4828c;
  /* margin-left: 20px; */
  width: 100px;
}

.btnCancel:hover {
  box-shadow: 0 0 30px 0 rgba(244, 130, 140, 0.7);
  background-color: #f4828c;
  color: white;
}

.btnUpdateCard {
  background-color: transparent;
  color: #8eb9a8;
  /* margin-left: 20px; */
  width: 100px;
}

.btnUpdateCard:hover {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #8eb9a8;
  color: white;
}

.btnUpgrade:disabled {
  background-color: lightgray;
  color: white;
}

.btnUpgrade {
  letter-spacing: 3px;
  color: #ffffff;
  background-color: #8eb9a8;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.16);
  margin: 0px 80px 0px auto;
}

.btnUpgrade p {
  color: #ffffff;
}

.btnUpgrade:hover:enabled p {
  color: #8eb9a8;
}

.btnUpgrade .upgradeIcon {
  background-image: url('/public/assets/icons/upgrade.svg');
  background-repeat: no-repeat;
  width: 19.3px;
  height: 22.1px;
  display: block;
  margin-right: 8px;
  transition: all 0.3s;
}

.btnUpgrade:hover:enabled {
  box-shadow: 0 0 30px 0 rgba(142, 185, 168, 0.7);
  background-color: #ffffff;
  color: #8eb9a8;
}

.btnUpgrade:hover:enabled .upgradeIcon {
  background-image: url('/public/assets/icons/upgrade-hover.svg');
  width: 19.3px;
  height: 22.1px;
}

/* UPGRADE COMPONENT */

.upgrade .info {
  width: 360px;
  height: 96px;
}

.info .current {
  margin: 20px 20px 20px 20px;
  text-align: right;
  color: #eacda3 !important;
}

.current .span {
  font-size: 10px;
  font-weight: 600;
}

.current p {
  font-size: 17px;
  font-weight: 300;
}

.info .seatPrice {
  margin-top: 0px;
  display: flex;
  align-items: center;
}

.info .seatPrice h1 {
  font-size: 40px;
  font-weight: 300;
  color: #eacda3 !important;
}

.info .seatPrice span {
  font-size: 13px;
  font-weight: 500;
}

/* Upgrade Plan holder + seat*/

.upgrade .plan {
  width: 536px;
  height: 402px;
}

.upgrade .plan .holder {
  margin: 10px 0px;
  width: 100%;
}

.upgrade .plan .holder > div {
  width: 228px;
  margin-top: 30px;
}

.upgrade .plan .holder > div > div {
  margin-top: 0px;
}

.upgrade .plan .holder > div:first-child {
  margin: 30px 30px 0px;
}

.upgrade .plan .holder input {
  text-align: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.upgrade .plan .holder label {
  position: initial;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
  color: #b7b7b9;
  transform: none;
}

.upgrade .seats {
  display: flex;
}

.seats .quantity {
  width: 179px;
  height: 173px;
  margin: 18px 30px 20px;
  box-shadow: 5px 5px 18px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.seats .quantity h3 {
  font-size: 21px;
  font-weight: 500;
  color: #365272;
  text-align: center;
  margin-bottom: 0.5em;
}

.seats .quantity button {
  width: 28.6px;
  height: 28.6px;
  margin: 0 15px;
  border: none;
  background-repeat: no-repeat;
  background-size: contain;
}

.seats .quantity .decrease {
  background-image: url('/public/assets/icons/minus.svg');
}

.seats .quantity .increase {
  background-image: url('/public/assets/icons/plus.svg');
}

.seats .quantity .quantityCount {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upgrade .quantity .quantityCount > div > div::before,
.upgrade .quantity .quantityCount > div > div::after {
  border: none;
}

.seats .quantity input {
  width: 70px;
  height: 50px;
  border: none;
  outline: none;
  font-size: 65px;
  font-weight: 300;
  text-align: center;
  color: #365272;
}

.upgrade .plan .seatPrice {
  margin-top: 18px;
  width: 277px;
  height: 173px;
  box-shadow: 5px 5px 18px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.upgrade .plan .btnUpgrade {
  width: 290px;
  margin: 10px auto 30px;
}

.upgrade .plan > p {
  font-size: 10px;
  font-weight: 500;
  color: #b7b7b9;
  text-align: center;
}
