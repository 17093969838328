.root {
  display: flex;
  align-items: flex-start;
  margin: 192px 48px;
  gap: 16px;
  box-sizing: border-box;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  box-sizing: border-box;
  width: 512px;
  max-height: 100%;
  padding: 32px;
  background-color: white;
  overflow-y: auto;
  transition: 0.3s ease-in-out;
}

.container.loading {
  height: 100%;
}

.container.noInteractions > div {
  pointer-events: none;
  user-select: none;
}

.container.blur > div:not(.message),
.container.blur > .header > .headerSwitch {
  opacity: 0.5;
  filter: blur(3px);
}

.message {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding: 64px;
}

.message > svg {
  font-size: 64px;
}

.commonContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.header {
  display: flex;
  align-items: center;
}

.headerSwitch {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 12px;
  margin-left: 12px;
  border-left: 1px solid var(--light-grey);
}

.label {
  margin: 0;
  font-weight: 400;
  font-size: 22px;
}

.labelWithSwitch {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noPermissionText {
  color: red;
  margin-bottom: 8px;
}

.placeholder {
  color: var(--grey);
}

@media screen and (max-width: 768px) {
  .root {
    margin: 0;
    flex: 1;
  }

  .container {
    width: 100%;
    height: 100%;
    padding: 24px;
    border: none;
    border-radius: 0;
  }
}
