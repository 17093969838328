.root {
  width: 100%;
  min-height: 100%;
  height: 100%;
  z-index: 10000;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.title {
  text-align: center;
  font-size: 24px;
}

.loadingItem {
  display: block;
  width: 80px;
  height: 80px;
}

.loadingItem div {
  position: absolute;
  border: 4px solid var(--main-color);
  opacity: 1;
  border-radius: 50%;
  animation: loading-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loadingItem div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes loading-ripple {
  0% {
    top: 196px;
    left: 334.5px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 160px;
    left: 298.5px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
