html {
  overflow: hidden;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  box-sizing: border-box;
  scrollbar-width: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*::-webkit-scrollbar {
  display: none;
}

.full-width {
  width: stretch;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
    opacity: 0.8;
  }
  to {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.spinner {
  animation: spin 3s linear 0.2s infinite;
}

div[class^='intercom'] {
  z-index: 10;
}

@-moz-document url-prefix() {
  .full-width {
    width: 100%;
  }
}

button,
input {
  font-family: 'Poppins', sans-serif;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}
