/**
*   Main box and content box layout
**/
.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainBox {
  width: 677px;
  height: 490px;
  background-color: #ffffff;
  margin: auto;
  vertical-align: middle;
}

.contentContainer {
  width: stretch;
  height: stretch;
}

.content {
  width: stretch;
  height: stretch;
}

.discardBox {
  color: var(--discard-color);
  background-color: #ffffff;
  padding: 13px 14px 10px 12px;
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.discardBox:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: var(--discard-color);
  box-shadow: 0 0 15px 0 #ffffff;
}
.discardBox.selected {
  color: #ffffff;
  background-color: var(--discard-color);
  box-shadow: 0 0 15px 0 #ffffff;
}
.discardBox span {
  font-size: 10px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 8px;
}

@media only screen and (min-height: 650px) {
  .discardBox {
    left: 554px;
    top: 20px;
  }
}

@media only screen and (max-height: 649px) {
  .discardBox {
    right: -700px;
    top: -490px;
  }
}

/** naming box */
.boxContent {
  padding: 60px 57px 5px;
  width: stretch;
}

.title {
  font-size: 25px;
  font-weight: 300;
  width: stretch;
  padding: 57px 0 5px 57px;
}

@-moz-document url-prefix() {
  .contentContainer {
    width: 95%;
    height: 95%;
  }

  .content {
    width: 95%;
    height: 95%;
  }
  /** naming box */
  .boxContent {
    width: 95%;
  }

  .title {
    width: 95%;
  }
}
