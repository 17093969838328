.projectContainer {
  box-sizing: border-box;
  margin: 15px;
  padding: 8px;
  width: 225px;
  height: 60px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 7px 7px 25px 0 var(--blur-shadow-color);
  transition: linear 0.1s;
  border-radius: 6px;
}
.projectContainer:not(.addProject) {
  border: solid 2px #ffffff;
  width: 221px;
}
.projectContainer:focus:not(.addProject),
.projectContainer:hover:not(.addProject) {
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
  border: solid 2px var(--main-color);
}
.projectContainer:hover {
  cursor: pointer;
}
/** TODO-checkstyle*/
.projectTitle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: stretch;
  height: stretch;
}
.projectmainTitle {
  width: 160px;
  font-size: 13px;
  font-weight: 300;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.projectsubTitle {
  color: var(--grey);
  font-size: 10px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}
.projectIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addProject {
  background-color: var(--green);
  color: white;
  justify-content: flex-start;
}
.addProject span {
  font-size: 17px;
  margin: 0 0 0 11px;
}

.avatar {
  height: 45px;
}

/* Submission Buttons */
.submissionBtns {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.submissionBtns > button {
  height: 35px;
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  font-family: Poppins;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 3px;
  border: none;
  display: flex;
  align-items: center;
  margin: 15px 30px;
  cursor: pointer;
  transition: all 0.3s;
}

.submissionBtns .saveBtn {
  color: #8eb9a8;
}

.submissionBtns .discardBtn {
  color: #f4828c;
}

.submissionBtns span {
  width: 15px;
  height: 15px;
  margin: 10px;
  transition: all 0.3s;
}

.submissionBtns > button:hover {
  box-shadow: 0 0 15px 0 #ffffff;
  color: #ffffff;
}

.submissionBtns > .discardBtn:hover {
  background-color: #f4828c;
}

.submissionBtns > .saveBtn:hover {
  background-color: #8eb9a8;
}

.submissionBtns .saveBtn span {
  background-image: url('/public/assets/icons/success-small-icon.svg');
}

.submissionBtns .discardBtn span {
  background-image: url('/public/assets/icons/fail-small-icon.svg');
}

.submissionBtns .saveBtn:hover span {
  background-image: url('/public/assets/icons/success-small-icon-hover.svg');
}

.submissionBtns .discardBtn:hover span {
  background-image: url('/public/assets/icons/fail-small-icon-hover.svg');
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paper {
  width: 515px;
  height: 164px;
  text-align: center;
  outline: none;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding-bottom: 15px;
}

.longTextpaper {
  height: auto;
}

.paper > div {
  flex-direction: row-reverse;
}
.longTextpaper > div {
  flex-direction: column-reverse;
}

.paper > div > button {
  width: 145px;
}

.oneInput {
  display: flex;
  outline: none;
}

.oneInputTitle {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.oneInputTitle p {
  margin: 14px 0px 0px 30px;
  padding-right: 10px;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  border-right: 1px solid rgba(0, 0, 0, 0.16);
}

.oneInputTitle span {
  font-size: 15px;
  margin: 15px 0px 0px 10px;
  font-weight: 500;
}

.oneInputBody {
  width: 515px;
  height: fit-content;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.oneInputTextField {
  width: 455px;
  margin: 0px 25px !important;
}

.oneInputBody > div:not(:first-child) {
  margin-bottom: 20px !important;
}

.oneInputTextField > div {
  padding: 12px !important;
}

.customInput {
  position: relative;
}

.customInputLimit {
  position: absolute;
  bottom: 4px;
  right: 8px;
  font-size: 10px;
  font-weight: 500;
  color: var(--grey);
}
