.node {
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  padding: 8px;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: grab;

  color: white;
  font-size: medium;
}
.node[data-has-children='false']:not([data-mode='builder']) {
  align-items: center;
  margin: -4px;
  padding: 16px 8px 16px 16px;
  justify-content: flex-start;
}

.node:hover {
  cursor: pointer;
  text-decoration: underline;
}

.repositoryName {
  color: white;
  white-space: nowrap;
}

.repositoryLogo {
  width: 26.7px;
  height: 26.7px;
}
.node[data-mode='builder'] .repositoryLogo {
  margin: 0 13px 10px 13px;
}

.drag {
  color: var(--mui-grey);
}

.drag:hover {
  cursor: grab;
}

.container {
  display: flex;
}

.path {
  font-size: 12px;
  opacity: 50%;
}
