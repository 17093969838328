.header {
  position: sticky;
  top: 0;
  z-index: 1;
  display: grid;
  grid-template-columns: 128px;
  column-gap: 16px;
  background-color: #f2f2f2;
  border-bottom: 1px solid var(--light-grey);
  min-width: 100%;
}

.column {
  white-space: nowrap;
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 8px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}

.sortIcon {
  display: grid;
  place-items: center;
}

.sortIcon[data-direction='asc'] {
  transform: scale(1, -1);
}
