.loadingContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  font-family: 'Poppins', sans-serif;
  overflow-y: scroll;
}

.dashboardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboardTitle {
  margin-top: 0px;
  margin-bottom: 13px;
  font-weight: 400;
  font-size: 1.7rem;
  color: #365272;
}

.dashboardFlex {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.dashboardItem {
  flex: 1 1 200px;
  min-width: 350px;
  border: 1px solid #ddd;
  padding: 16px;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
  background-color: #fff;
  text-decoration: none;
}

.dashboardItemHeading {
  font-size: 1.35rem;
  font-weight: 400;
  margin-bottom: 18px;
  padding: 10px;
  color: #365272;
  display: flex;
  justify-content: space-between;
}

.itemCount {
  font-weight: 500;
  margin-left: auto;
}

.downloadSbomModal {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}

.downloadSbomModalContent {
  box-sizing: border-box;
  max-width: 400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.downloadSbomModalBody {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media only screen and (max-width: 1100px) {
  .root {
    flex-direction: column;
    padding: 12px;
    gap: 12px;
  }

  .dashboardHeader {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
