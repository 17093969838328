.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

:root {
  --main-color: #365272;
  --main-color-hover: #2c3e4f;
  --main-color-disabled: #b3b3b3;
  --disable-color: #707070;
  --white: #ffffff;
  --white-hover: #f2f2f2;
  --black: #000000;
  --black-hover: #1b1b1b;
  --grey: #b7b7b9;
  --mui-grey: #c4c4c4;
  --green: #8eb9ab;
  --light-grey: #e6e6e6;
  --blue: #3669a3;
  --discard-color: #f4828c;
  --github-color: #3a4148;
  --github-color-hover: #2a3136;
  --azure-color: #0078d4;
  --azure-color-hover: #0063b1;
  --blur-shadow-color: rgba(0, 0, 0, 0.16);
  --alter-blur-bg-color: rgba(199, 199, 199, 0.95);
  --btn-shadow-color: rgba(0, 89, 190, 0.4);
  --black-shadow: 2px 2px 15px 0 var(--blur-shadow-color);
  --big-block-shadow: 10px 10px 40px 0 var(--blur-shadow-color);
  --vulnerability-link-color: rgb(247, 133, 10);
  --vulnerability-low-color: rgb(255, 193, 0);
  --vulnerability-moderate-color: rgb(255, 152, 1);
  --vulnerability-high-color: rgb(255, 86, 7);
  --vulnerability-critical-color: rgb(230, 54, 25);
  --external-service-color: #6cb8cf;
}

p {
  margin: 0;
}

.border {
  border: 1px solid var(--light-grey);
  border-radius: 6px;
}
