.container {
  display: grid;
  place-items: center;
}

.fullWidth {
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.grow {
  flex: 1;
}
