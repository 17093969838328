.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.node {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: -4px;
  padding: 16px;
  padding-right: 8px;
  border: 3px solid var(--main-color);
  border-radius: 8px;
  color: black;
  background-color: white;
  user-select: none;
}

.handle {
  visibility: hidden;
}

.container {
  display: flex;
  flex-direction: column;
}

.icon {
  display: grid;
  place-items: center;
  color: #ff9900;
}
