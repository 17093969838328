.filters {
  display: flex;
  gap: 20px;
}

.filters > * {
  flex: 1;
}

.table {
  max-width: 100%;
  min-height: 0;
  overflow-y: scroll;
  overflow-x: auto;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }
}
