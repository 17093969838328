.root {
  position: initial;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--light-grey);
  box-sizing: border-box;
  background-color: white;
}

.leftHeader {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px 0 24px;
}

.rightHeaderContainer {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  align-items: center;
}

.mobileHeaderContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.rightHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  padding: 16px 24px;
  right: 0;
  z-index: 1200;
  float: right;
}

/* Mobile */
.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background-color: white;
  border-top: 1px solid var(--light-grey);
  box-sizing: border-box;
  z-index: 1000;
}

.workspaceSelector {
  display: flex;
  justify-content: space-between;
  border: 2px solid var(--light-grey);
  border-radius: 6px;
  padding: 16px;
  font-weight: 500;
  user-select: none;
  cursor: pointer;
}

/* Left panel*/
.logoPanel {
  display: flex;
  align-items: center;
  gap: 8px;
}

.logoPanel .workspaceSwitch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 45px;
  padding: 10px;
  transition: all linear 0.3s;
}

.logoPanel .workspaceSwitch:hover {
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
  border-radius: 50px;
  cursor: pointer;
}

.logoPanel .logoImg {
  width: 45px;
  height: 45px;
  object-fit: contain;
  display: inline-block;
  z-index: 1200;
  position: fixed;
  border-radius: 50%;
  box-shadow: var(--black-shadow);
  background: url('/public/assets/logos/sg-logo-white.svg') no-repeat;
}

.logoPanel .teamInfo {
  min-width: 200px;
  max-width: 250px;
  margin-left: 60px;
}

.logoPanel .teamInfo .teamContainer {
  font-size: 12px;
  font-weight: bold;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  color: var(--grey);
}

.logoPanel .teamInfo .projectContainer {
  color: #000000;
  font-size: 20px;
  font-weight: 300;
  text-overflow: ellipsis;
  max-width: 250px;
  overflow: hidden;
  display: block;
  white-space: nowrap;
}

.logoPanel .btnContainer {
  height: -webkit-stretch;
  display: inline-flex;
  align-items: center;
  margin: 10px;
  padding: 5px;
}

/* Middle */

.tabPanel {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  gap: 16px;
}

.tabPanel::before {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  border-radius: 8px;
  top: 16px;
  left: 0;
  bottom: 16px;
  background-color: var(--light-grey);
}

.logoContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
}

.logoContainer > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.logoContainer > a {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

/* Right panel */

.popup {
  right: 0;
  top: 50px;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 270px;
  padding: 16px;
  background-color: white;
  color: black;
  border: 1px solid var(--light-grey);
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 3px,
    rgba(0, 0, 0, 0.05) 0px 28px 23px -7px,
    rgba(0, 0, 0, 0.04) 0px 12px 12px -7px;
  z-index: 1201;
}

.notification {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-left: 8px;
  font-weight: 500;
  color: #424548;
}

.notification > span:last-child {
  color: var(--grey);
}

.notification::before {
  content: '';
  display: block;
  position: absolute;
  width: 6px;
  border-radius: 8px;
  top: 8px;
  left: 8px;
  bottom: 8px;
  background-color: #d8152b;
}

.licenseNotification {
  cursor: pointer;
}

.licenseNotification:hover {
  text-decoration: underline;
}

.resultBox {
  right: 50px;
  top: 60px;
}

.popupContainer {
  position: relative;
}

.menuBox {
  right: 0;
  top: 50px;
}

.searchInfo {
  text-align: center;
}

.reportItem {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  padding: 8px;
  border-radius: 6px;
}

.reportItem .textBox {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.reportItem:hover {
  background-color: var(--light-grey);
}

.notiBtn .notiIcon::before {
  content: '\f0f3';
}

.menuItems {
  display: flex;
  flex-direction: column;
}
.menuItem {
  padding: 4px;
}
.menuItem:hover {
  background-color: var(--light-grey);
  border-radius: 6px;
}
/** Responsive */
@media only screen and (max-width: 550px) {
  .logoPanel .teamInfo {
    max-width: 200px;
  }
  .logoPanel .teamInfo .projectContainer {
    max-width: 200px;
  }
}

@media only screen and (max-width: 1100px) {
  .desktop {
    display: none;
  }
}

@media only screen and (min-width: 1101px) {
  .mobile {
    display: none;
  }
}
