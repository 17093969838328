.row {
  position: absolute;
  min-width: 100%;
  display: grid;
  column-gap: 16px;
  border-bottom: 1px solid var(--light-grey);
  box-sizing: border-box;
}

.row[data-odd='true'] {
  background-color: #fbfbfb;
}

.cell {
  white-space: nowrap;
  padding: 8px;
}
