.sidebarSecondaryContainer {
  display: flex;
  overflow-y: hidden;
  width: 100%;
  scrollbar-width: none; /** For firefox*/
}

.sidebarSecondaryContainer::-webkit-scrollbar-track {
  display: none;
}

.sidebarSecondaryContainer::-webkit-scrollbar {
  display: none;
}

.sidebarSecondaryContainer::-webkit-scrollbar-thumb {
  display: none;
}

.sidebarSecondary {
  display: flex;
  flex-flow: column;
  flex-basis: 230px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
}

.sidebarTertiary {
  overflow-y: scroll;
  scrollbar-width: none;
}

.categoryTitle {
  padding-left: 30px;
  padding-right: 10px;
  margin-top: 51px;
}

.categoryTitleSmall {
  margin: 0px;
  font-family: Poppins;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #b7b7b9;
  transition: all 0.3s;
}

.categoryTitleLarge {
  margin: 0px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  display: flex;
  align-items: center;
  transition: all 0.3s;
}

.categoryItems {
  margin-top: 32px;
}

/* BUTTON */
.deleteProjectBtn {
  color: #f4828c;
  font-size: 15px;
  display: flex;
  padding: 0px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  align-items: center;
  margin-top: auto;
  margin-bottom: 20px;
  transition: all 0.3s;
  outline: none;
}

.deleteProjectBtn .iconBtn {
  background-image: url('/public/assets/icons/trash-solid-red.svg');
  display: block;
  margin: 10px 10px 10px 30px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  transition: all 0.3s;
}

.deleteProjectBtn:hover {
  background-color: #f4828c;
  color: #ffffff;
}

.deleteProjectBtn:hover .iconBtn {
  width: 20px;
  height: 20px;
  background-image: url('/public/assets/icons/trash-solid-white.svg');
}

.addWorkspaceBtn {
  border: none;
  width: 230px;
  height: 73px;
  background-color: #eacda3;
  text-shadow: 2px 2px 15px rgba(0, 0, 0, 0.16);
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 3px;
  color: #ffffff;
  transition: all 0.3s;
  display: flex;
  flex-flow: column;
  margin-top: 90px;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
}

.addWorkspaceBtn .iconBtn {
  background-image: url('/public/assets/icons/add-workspace-icon.svg');
  display: block;
  margin: 10px 0px;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
}

.addWorkspaceBtn:hover {
  box-shadow: 0 0 30px 0 rgba(234, 205, 163, 0.7);
}

/* CATEGORY */

.categoryRoot {
  display: flex;
  margin: 123px 0px;
}

.category {
  overflow-y: scroll;
}

.categoryBox {
  width: 367px;
  height: 562px;
  margin: 0px 30px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  position: relative;
}

.categoryItem {
  padding: 15px 0px 15px 30px;
  cursor: pointer;
  transition: all 0.3s;
}

.categoryItem:hover,
.categoryItem.selected {
  background-color: #365272;
}

.categoryItem:hover .categoryTitleSmall,
.categoryItem.selected .categoryTitleSmall,
.categoryItem:hover .categoryTitleLarge,
.categoryItem.selected .categoryTitleLarge {
  color: #fff;
}

.categoryEditIcon {
  height: 14px;
  margin-left: 13px;
  cursor: pointer;
}

.categoryHelpIcon {
  margin: 0 30px 0px auto;
  cursor: pointer;
}

/* For firefox */
@-moz-document url-prefix() {
  .category {
    scrollbar-width: none;
  }
}

@media screen and (max-width: 768px) {
  .sidebarTertiary {
    flex: 1;
  }
}
