/**
*********************************************************************
*                            .root                                  *
*********************************************************************
*    30%     *                     70%                              *
*            *                                                      *
* .container *               .container                             *
* .impactList*               .reportContainer                       *
*-Container  *                                                      *
*            *                                                      *
*            *                                                      *
* .impactList*                  .reportBox                          *
*            *                                                      *
*            *                                                      *
*********************************************************************
*/

.root {
  flex: 1;
  width: 100%;
  display: grid;
  grid-template-columns: 30% 70%;
  transition: all 1s;
}

.container {
  padding: 24px 24px 24px 0;
  overflow: -moz-scrollbars-none;
  overflow-y: auto;
  scrollbar-width: none; /** For firefox*/
  -ms-overflow-style: none;
}
.container::-webkit-scrollbar {
  display: none;
}

.subContainer {
  flex: 1;
  width: 100%;
  padding: 24px;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none; /** For firefox*/
  transition: linear 1s;
  box-sizing: border-box;
  z-index: 200;
}

.impactListContainer {
  flex: 1;
  overflow-y: scroll;
  z-index: 1;
}

.reportsRelatedToPr {
  margin-left: 20px;
}

.instructionsButton {
  min-width: 200px;
  margin: auto;
  padding: 16px;
  font-size: 18px;
}

.exampleImpactReport {
  float: right;
  border: 2px dotted black;
  border-radius: 15px;
  font-weight: 700;
  padding: 5px 10px;
}

.reportBox {
  background-color: white;
  scrollbar-width: none; /** For firefox */
}
.reportBox::-webkit-scrollbar {
  display: none;
}

/* Impact list and items - left column */
.impactItem {
  background-color: #ffffff;
  margin: 10px 0px;
  box-shadow: 3px 3px 20px 0 var(--blur-shadow-color);
  width: stretch;
  height: fit-content;
  transition: linear 0.1s;
  padding: 15px 20px 10px;
  border: solid 2px white;
  border-radius: 6px;
  text-decoration: none !important;
  color: black !important;
}

.impactItem:hover {
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
}
.impactItem.selected {
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
  border: solid 2px var(--main-color);
}

.noImpactItem {
  height: 100%;
  padding-bottom: 64px;
  transition: linear 0.1s;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-row-gap: 12px;
  box-sizing: border-box;
}
.noImpactItem span {
  text-align: center;
}
.iconAsImg {
  font-size: 60px;
  color: var(--grey);
}

.pullRequestList {
  margin-top: 35px;
}

.pullRequestList:last-child {
  margin-bottom: 30px;
}

.pullRequest {
  padding: 16px;
  margin-bottom: 16px;
}

.oldReport {
  font-size: 0.85rem;
}

.impactTitle {
  font-size: 15px;
}

.impactsubTitle {
  color: var(--grey);
  font-size: 13px;
}

.impactService {
  font-size: 13px;
  display: inline-grid;
  align-items: stretch;
  justify-content: flex-start;
  grid-column-gap: 5px;
  grid-template-columns: auto auto;
  margin-top: 10px;
}

/* Report container box - right column */

.contentRoot {
  width: stretch;
  display: flex;
  align-content: center;
}

.loadingScreen {
  align-content: center;
  align-self: center;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.loadingScreen span {
  display: block;
}

.loadingScreen span:nth-child(1) {
  margin-bottom: 10px;
  font-size: 45px;
  font-stretch: wider;
}

.contentContainer {
  width: 100%;
  max-width: 100%;
  padding: 32px;
  box-sizing: border-box;
}

.backButtonContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 16px;
  color: var(--main-color);
  text-decoration: none;
}

/** Below is the duplicate for root, list container, and impactList */
.rootSmall {
  display: block;
  transition: all 0.1s;
}
.impactListContainerSmall {
  overflow-y: scroll;
  background-color: white;
}
.reportBoxSmall {
  margin: 0;
}

@media only screen and (max-width: 768px) {
  .root {
    display: flex;
    transition: all 0.1s;
  }
  .impactListContainer {
    display: none;
  }
  .reportContainer {
    padding: 0;
  }
  .reportBox {
    margin: 0;
    border: none !important;
  }
}

@media only screen and (min-width: 769px) {
  .backButtonContainer {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .impactListContainerSmall {
    padding: 0;
  }
  .pullRequest {
    box-shadow: none !important;
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 1px solid var(--light-grey) !important;
  }
  .contentContainer {
    padding: 32px 24px 24px;
  }
}

@media only screen and (max-width: 768px) {
  .subContainer {
    padding: 0;
  }
}

/** Responsive */
@media only screen and (min-width: 1420px) {
  .impactsubTitle {
    display: inline-flex;
    justify-content: space-between;
    align-items: stretch;
  }
}
