.instructionModal {
  position: fixed;
  width: 80%;
  max-height: 90vh;
  left: 10%;
  top: 5%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-color: white;
  padding: 32px;
  outline: none;
  text-align: center;
  border-radius: 6px;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.closeModal {
  float: right;
  color: grey;
}

.onTop {
  z-index: 30000000;
}

.heading {
  font-weight: 400;
  margin-block-end: 0px;
}

.logo {
  width: 45px;
  height: 45px;
  object-fit: contain;
  float: left;
  background: url('/public/assets/logos/sg-logo-white.svg') no-repeat;
}

.detailedText {
  font-size: 12px;
  text-align: center;
}

.specificInstructions {
  padding-top: 10px;
}

.instructionsButton {
  width: 40%;
}

@media only screen and (min-width: 1500px) {
  .instructionModal {
    top: 10%;
  }

  .detailedText {
    padding-top: 20px;
    font-size: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .instructionModal {
    width: 90%;
    max-height: 90%;
    left: 5%;
    top: 5%;
    padding: 16px;
  }
  .heading {
    font-size: 1.2rem;
  }
}

.instructions {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.instruction {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px;
}

.instruction::before {
  content: '';
  display: block;
  position: absolute;
  width: 6px;
  border-radius: 8px 0 0 8px;
  top: -1px;
  left: -1px;
  bottom: -1px;
}

.instruction:nth-child(1)::before {
  background-color: var(--disable-color);
}

.instruction:nth-child(2)::before {
  background-color: var(--main-color);
}

.instruction:nth-child(3)::before {
  background-color: #24292e;
}

.instruction:nth-child(4)::before {
  background-color: var(--green);
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px;
}

.instruction:nth-child(1) > .icon {
  color: var(--disable-color);
}

.instruction:nth-child(2) > .icon {
  color: var(--main-color);
}

.instruction:nth-child(3) > .icon {
  color: #24292e;
}

.instruction:nth-child(4) > .icon {
  color: var(--green);
}

.text {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 32px;
  text-align: left;
}

.label {
  font-weight: 500;
}

.description {
  font-size: 14px;
}
