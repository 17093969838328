.root {
  overflow-y: scroll;
  margin: 0;
  width: 100%;
  height: 90vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.tabButtons {
  display: flex;
  gap: 8px;
}

.modal {
  position: relative;
  left: 10%;
  top: 5%;
  width: 80vw;
  max-height: 90vh;
  box-sizing: border-box;
  background-color: white;
  padding: 20px;
  outline: none;
  text-align: center;
}

.closeModal {
  float: right;
  color: grey;
}

.projectList {
  list-style: none;
}

.divLeft {
  float: left;
}

.divRight {
  float: right;
}

.teamStatus {
  padding: 10px;
  margin: 10px;
  border-radius: 10px;
  font-weight: 300;
  width: 350px;
  background-color: #dfdce5ff;
  box-sizing: border-box;
}

.teamId {
  font-size: small;
}

.teamNameAndLicense {
  display: flow-root;
}

.jobsButton {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  height: 23px;
  background-color: #eac435;
  font-size: 0.875rem;
}

.noJobsOrProjects {
  font-size: 0.875rem;
  border-radius: 10px;
  background-color: var(--main-color);
  color: white;
  padding: 10px;
  margin: 5px 0;
}

.license {
  height: 25px;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  color: white;
}

.licenseValid {
  background-color: var(--green);
}

.licenseInValid {
  background-color: rgb(232 47 22);
}

.padding {
  padding: 4px;
}

.trialCheckbox {
  padding-left: 40px;
}

@media only screen and (max-width: 350px) {
  .teamStatus {
    width: 90vw;
  }
}
