.container {
  flex: 1;
  flex-direction: column;
  display: flex;
  border-color: var(--grey) !important;
  min-height: 0;
}

.graphContainer {
  flex: 1;
  display: flex;
  min-height: 0;
}

.pathBar {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 10px;
  border-bottom: 1px solid var(--mui-grey);
  user-select: none;
}

.pathBar > p:hover {
  cursor: pointer;
  text-decoration: underline;
}

.controlContainer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  border-bottom: 1px solid var(--mui-grey);
  gap: 32px;
}
.controlContainer > .modeButtons {
  margin-right: auto;
}
