.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--mui-grey);
  cursor: pointer;
  user-select: none;
  transition: margin 0.2s;
}

.container > label {
  cursor: pointer;
}

.container:hover {
  background-color: var(--white-hover);
}

.checked {
  margin-bottom: 16px;
}
