.container {
  box-sizing: border-box;
  display: flex;
  padding: 16px;
  gap: 16px;
  width: 608px;
  height: 416px;
  transition: all 0.3s ease-in-out;
}

.tabs,
.models {
  display: flex;
  flex-direction: column;
  gap: 4px;
  overflow-y: auto;
}

.models {
  flex: 1;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: linear 0.1s;
}

.tab[data-selected='true'] {
  background-color: var(--main-color);
  color: white;
}

.model {
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: linear 0.1s;
}

.tab:hover[data-selected^='false'],
.model:hover {
  background-color: var(--white-hover);
}

.header {
  padding: 8px 16px;
}

.back {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  color: var(--main-color);
}

@media only screen and (max-width: 992px) {
  .container {
    width: 100%;
    height: 100%;
    padding: 24px 0;
  }
  .tabs {
    flex: 1;
  }
}
