.root {
  width: 515px;
  height: 660px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin: 129px 0px 0px 30px;
  padding-top: 1px;
  padding-bottom: 21px;
}

.root h2 {
  font-size: 20px;
  font-weight: 300;
  color: #000000;
  margin: 19px 0px 10px 30px;
}

.root p {
  font-size: 10px;
  font-weight: 300;
  margin: 10px 30px;
  color: #000000;
}

.token {
  width: 455px;
  height: 235px;
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin: 13px 30px 20px;
  padding-top: 8px;
}

.token h2,
.revoke h2 {
  margin: 0px 14px 16px;
}

.token p {
  margin: -14px 14px 10px;
}

.token > div,
.revoke > div {
  margin: 0px 15px 15px;
}

.token > div > div,
.revoke > div > div {
  display: flex;
  width: 423px;
  height: 110px;
  border: solid 1px #b7b7b9;
  align-items: flex-start;
  padding: 6.5px 8px;
}

.token .create,
.revoke button {
  font-size: 15px;
  font-weight: 300;
  color: #ffffff;
}

.token .create {
  width: 385px;
  height: 35px;
  background-color: #8eb9a8;
}

.token .create:hover {
  box-shadow: 0 0 30px 0 rgba(142, 185, 168, 0.7);
  background-color: #8eb9a8;
}

.token .clipboard {
  width: 30.1px;
  height: 35px;
  object-fit: contain;
  background-repeat: no-repeat;
  background-image: url('/public/assets/icons/copy.svg');
  background-size: 80%;
  padding: 2px;
  background-position: center;
  margin-left: 9px;
}

.revoke {
  width: 455px;
  height: 222px;
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  margin: 13px 30px 20px;
  padding-top: 8px;
}

.revoke .delete {
  width: 423px;
  height: 35px;
  background-color: #f4828c;
  transition: all 0.3s;
  margin: 0px 15px;
}

.revoke .delete:hover {
  box-shadow: 0 0 30px 0 rgba(244, 130, 140, 0.7);
  background-color: #f4828c;
}
