.container {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 24px;
  padding: 0 24px;
  overflow-y: auto;
  box-sizing: border-box;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  gap: 16px;
}

@media screen and (max-width: 992px) {
  .container {
    padding: 0 16px;
  }
}
