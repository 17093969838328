.container {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none;
}

.centered {
  display: grid;
  place-items: center;
  text-align: center;
}

.cycles {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 16px;
  margin-bottom: 16px;
}

.cycles:last-child {
  margin-bottom: 0;
}

.cycle {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.cycleLabel {
  font-size: 18px;
}

.noCycles {
  font-size: 20px;
}

.emoji {
  font-size: 128px;
}

@media screen and (max-width: 1200px) {
  .cycles {
    grid-template-columns: 1fr;
  }
  .noCycles {
    font-size: 16px;
  }
  .cycleLabel {
    font-size: 16px;
  }
  .emoji {
    font-size: 64px;
  }
}
