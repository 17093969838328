.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
}

.progress {
  width: 256px;
}

.errorContainer {
  gap: 24px;
}

.errorLabel {
  font-size: 16px;
}

.errorText {
  font-size: 14px;
  color: var(--grey);
}

.icon {
  font-size: 96px;
  line-height: 0;
}

.emoji {
  font-size: 96px;
  line-height: 1;
}

@media screen and (max-width: 1200px) {
  .emoji {
    font-size: 64px;
  }
}
