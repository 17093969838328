.ldapRoot {
  display: flex;
  margin: 127px 30px;
}

.ldapForm {
  width: 515px;
  min-height: 415px;
  padding-bottom: 30px;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  overflow-y: auto;
  max-height: calc(100vh - 250px);
}

.ldapForm h2 {
  font-size: 20px;
  font-weight: 300;
  margin-top: 30px;
  margin-left: 30px;
}

.ldapRoot > .configuration:last-child {
  margin-bottom: 30px;
}

.configuration {
  width: 455px;
  min-height: 71px;
  margin: 18px 30px 0px;
  box-shadow: 7px 7px 25px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  transition: all 0.3s;
}

.configuration:hover {
  box-shadow: 0 0 30px 0 rgba(0, 89, 190, 0.4);
}

.integrationConfig {
  display: flex;
  align-items: center;
  height: 71px;
}

.configuration img {
  margin: 0px 14px 0px 18px;
  height: 43px;
  width: 43px;
}

.ldapInputContainer .ldapAuth {
  padding: 0;
  margin: 0px 16px;
  width: 395px;
}

.ldapInputContainer .ldapInput {
  margin: 4px 16px 5px;
  width: 395px;
}

.ldapInput {
  background-color: transparent;
  width: 251px;
  margin-top: 21px;
  margin-left: 16px !important;
}

.ldapInput div:before,
.ldapInput div:after {
  border-bottom-color: transparent;
  max-width: 395px !important;
  width: 395px;
}
.ldapInput:hover div:before,
.ldapInput:hover div:after {
  border-bottom-color: #b7b7b9;
  max-width: 395px !important;
  width: 395px;
}
