.container {
  min-width: 128px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
}

.fullHeight {
  height: 100%;
}

.confirm {
  background-color: #8eb9a8;
}

.confirm:hover {
  background-color: #7daa99;
}

.cancel {
  background-color: #e8a8a8;
}

.cancel:hover {
  background-color: #d99a9a;
}
