.sidebar {
  min-height: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 24px 0;
  box-sizing: border-box;
}

.sidebarHeader {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 8px;
  box-sizing: border-box;
}

.changeModelButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--grey);
  border-radius: 6px;
  padding: 16px;
  user-select: none;
  cursor: pointer;
  gap: 8px;
  transition: linear 0.1s;
}

.changeModelButton:hover {
  border: 1px solid var(--main-color);
}

.modelInfo {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: auto;
}

.modelName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.modelTime {
  font-size: 12px;
  color: var(--grey);
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .sidebar {
    width: none;
    flex: 1;
    padding: 16px 0;
  }
}
