.teamContainer {
  width: 100%;
  box-sizing: border-box;
}

.titleContainer {
  width: inherit;
  border: none;
  color: #ffffff;
  padding-bottom: 7px;
}
.hr {
  width: calc(100vw - 210px);
  border: white 1.5px solid;
}
.title {
  color: white;
  text-shadow: var(--black-shadow);
  font-size: 15px;
  padding-left: 30px;
}
.constText {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 3px;
}

.allProjectsContainer {
  margin: auto;
  margin-top: 40px;
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 15px;
}
.projectContainer {
  margin: 15px;
  width: 225px;
  height: 60px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 7px 7px 25px 0 var(--blur-shadow-color);
  transition: linear 0.1s;
}
.projectContainer:not(.addProject) {
  border: solid 2px #ffffff;
  width: 221px;
  height: 56px;
}
.projectContainer:focus:not(.addProject),
.projectContainer:hover:not(.addProject) {
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
  border: solid 2px var(--main-color);
}
.projectContainer:hover {
  cursor: pointer;
}
.projectTitle {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: stretch;
  margin-left: 10px;
  height: stretch;
}
.projectmainTitle {
  font-size: 13px;
  font-weight: 300;
  color: #000000;
}
.projectsubTitle {
  color: var(--grey);
  font-size: 10px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}
.projectIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.addProject {
  background-color: var(--green);
  color: white;
  justify-content: flex-start;
}
.addProject span {
  font-size: 17px;
  margin: 0 0 0 11px;
}

/** Responsive */
@media only screen and (min-width: 1270px) and (max-width: 1550px) {
  .allProjectsContainer {
    width: 1020px;
  }
}
@media only screen and (min-width: 1550px) {
  .allProjectsContainer {
    width: 1275px;
  }
}
@media only screen and (max-width: 1270px) {
  .allProjectsContainer {
    width: 500px;
    margin: auto;
    justify-content: center !important;
  }
}

@media only screen and (max-width: 768px) {
  .allProjectsContainer {
    width: 100%;
    justify-content: center !important;
  }
  .hr {
    width: unset;
    border: white 1.5px solid;
  }
  .projectContainer {
    padding: 20px 5px;
  }
}
