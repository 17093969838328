.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
  transition: all 1s;
  padding: 10px 0px;
  color: var(--main-color);
}
.versionContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 1.2rem;
  padding: 12px;
  border: solid 2px var(--light-grey);
  border-radius: 6px;
}

.header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.packageName {
  margin-left: 8px;
  word-break: normal;
  overflow-wrap: anywhere;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  gap: 12px;
}

.flexitem {
  font-size: 1rem;
  padding: 12px;
  border: 1px solid var(--blue);
  border-radius: 6px;
  box-shadow: 0 0 10px 0 var(--blur-shadow-color);
}

.info {
  font-size: 0.7rem;
  padding-top: 8px;
}

.versionSpan {
  padding-left: 10px;
}

.notFound {
  text-align: center;
  padding: 32px 12px;
}

/* Responsive layout - makes a one column-layout instead of two-column layout */
@media (max-width: 768px) {
  .flexContainer {
    flex-direction: column;
  }
}
