.root {
  width: 100%;
  min-height: 100%;
  height: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: none;
  background-color: var(--blur-shadow-color);
  backdrop-filter: blur(26.3px);
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
}

.firefoxBg {
  display: none;
}

.transparentHeader {
  height: 100px;
  position: sticky;
  top: 0;
  width: inherit;
  z-index: 200000;
  align-self: flex-start;
}

.show {
  display: flex;
}

.hidden {
  display: none;
}

.contentBox {
  width: stretch;
  height: calc(100vh - 200px);
  display: block;
  z-index: 100000;
  position: fixed;
  top: 90;
  padding: 105px 115px 0 105px;
  overflow-y: scroll;
  box-sizing: content-box;
  scrollbar-width: none;
}

.fixedBtnContainer {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  width: stretch;
  /* height: 100px; */
  align-self: flex-end;
  z-index: 100000;
}

.closeBtn {
  color: var(--grey);
  position: absolute;
  bottom: 30px;
  right: 42.5px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background-color: white;
  display: flex;
  align-items: center;
  box-shadow: var(--black-shadow);
  transition: linear 0.1s;
  z-index: 200000;
}

.closeBtn::after {
  content: '\f00d';
  color: var(--grey);
  position: absolute;
  text-align: center;
  font-size: 24px;
  right: 15px;
}

.closeBtn::before {
  content: 'Close';
  position: absolute;
  left: -80px;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 2px 2px 15px var(--blur-shadow-color);
  font-family: Poppins;
}

.closeBtn:hover {
  cursor: pointer;
}

.signoutBtn {
  z-index: 200000;
  color: white;
  position: absolute;
  bottom: 30px;
  left: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  transition: linear 0.1s;
}
.signoutBtn i,
.signoutBtn span {
  display: inline-flex;
  text-shadow: 2px 2px 15px var(--blur-shadow-color);
}
.signoutBtn i {
  font-size: 24px;
}
.signoutBtn span {
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 1.6px;
  line-height: 1.2;
}
.signoutBtn:hover,
.signoutBtn:focus {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .contentBox {
    padding: 105px 50px 0;
  }
}

@media only screen and (max-width: 610px) {
  .contentBox {
    padding: 105px 20px 0;
  }
}

@media only screen and (max-width: 550xp) {
  .contentBox {
    padding: 105px 5px 0;
  }
}
/** For firefox only */
@-moz-document url-prefix() {
  .root {
    background-color: var(--alter-blur-bg-color);
  }
  .contentBox {
    left: 0;
  }
  .fixedBtnContainer {
    width: 100vw;
  }
}
