.panel {
  display: flex;
}

.panel[data-dir='left'] {
  border-left: 1px solid var(--mui-grey);
}

.panel[data-dir='right'] {
  border-right: 1px solid var(--mui-grey);
}

.description {
  font-size: 12px;
  opacity: 40%;
}

.content {
  position: relative;
  /* Override ResizableBox height */
  height: 100% !important;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-right: 1px solid var(--mui-grey);
  overflow-y: auto;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.resizable {
  position: relative;
  /* Override ResizableBox height */
  height: 100% !important;
}
.handle {
  position: absolute;
  width: 8px;
  height: 100%;
}
.handle:hover {
  background-color: var(--mui-grey);
}
.handle:active {
  background-color: var(--blue);
}
.handle[data-dir='w'] {
  left: -1px;
  top: 50%;
  transform: translateY(-50%);
  cursor: col-resize;
}
.handle[data-dir='e'] {
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
  cursor: col-resize;
}
