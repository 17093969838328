.button {
  position: relative;
  display: grid;
  place-items: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.4s ease, color 0.4s ease;
}

.button:focus-visible {
  transition: none;
  outline: var(--main-color) solid 2px;
  outline-offset: 2px;
}

.button:hover {
  background-color: var(--white-hover);
}

.transparent {
  background-color: transparent;
}

.filled {
  background-color: var(--main-color);
  color: white;
}

.filled:hover {
  background-color: var(--main-color-hover);
}

.light {
  color: var(--grey);
}

.subtle {
  background-color: var(--white-hover);
}

.subtle:hover {
  background-color: var(--light-grey);
}
