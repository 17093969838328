.actionMenuContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 100;
  background-color: white;
  min-width: 192px;
  border-radius: 6px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
}

.actionMenuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  color: black;
}
.actionMenuItem:hover {
  background-color: var(--main-color-hover);
  color: white;
  cursor: pointer;
}
.actionMenuItem:first-child {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.actionMenuItem:last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
