/**
    - Style of this AddReposBox is similar to AddNewProjectBox
    - The only different, which will be modify are .root and .mainBox: position of the box to show, 
      and the discard button is not shown on the first screen 
      (choose platform and show list of repos in project)
**/
.root {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainBox {
  width: 677px;
  height: 490px;
  background-color: #ffffff;
  margin: auto;
  vertical-align: middle;
}

.contentContainer {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: 100%;
}

.content > div.subBox {
  width: 100%;
  height: 100%;
}

.discardBox {
  color: var(--discard-color);
  background-color: #ffffff;
  padding: 13px 14px 10px 12px;
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.discardBox:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: var(--discard-color);
  box-shadow: 0 0 15px 0 #ffffff;
}
.discardBox.selected {
  color: #ffffff;
  background-color: var(--discard-color);
  box-shadow: 0 0 15px 0 #ffffff;
}
.discardBox span {
  font-size: 10px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: 8px;
}

@media only screen and (min-height: 650px) {
  .discardBox {
    left: 554px;
    top: 20px;
  }
}

@media only screen and (max-height: 649px) {
  .discardBox {
    right: -700px;
    top: -490px;
  }
}

/*
SUB BOXES
*/
.titleBox {
  width: stretch;
  padding-top: 57px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.titleBox > div {
  min-width: 10px;
  text-align: center;
  font-size: 25px;
  font-weight: 300;
  padding: 0 5px;
}

/* choose git platform */

.mainChoosePlatform {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  margin-top: 20px;
}

.platformBox {
  display: inline-flex;
  margin-bottom: 30px;
  box-shadow: 5px 5px 18px 0 var(--blur-shadow-color);
  width: 240px;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  transition: linear 1s all;
  flex-direction: row;
}

.platformBox:hover,
.platformBox.selected {
  cursor: pointer;
  box-shadow: 0 0 30px 0 var(--btn-shadow-color);
}

.platformBox div {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 1px;
  margin-left: 5px;
}

.platformBox img {
  width: 42px;
  height: 42px;
}

.platformBoxTitle {
  font-size: 20px;
  font-weight: 300;
  text-transform: capitalize;
}

.icon {
  font-size: 12px;
  color: var(--main-color);
  padding: 5px 10px;
}

.icon:hover {
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  color: red;
}

.configStatus {
  font-size: 10px;
  color: var(--grey);
}
.configStatus .good {
  color: #4ec85b;
}
.configStatus .unknown {
  color: var(--grey);
}
.configStatus .warn {
  color: #e5242d;
}

/** Git sub platform box */
.chooseSubPlatformContentBox {
  padding: 40px 20px;
}

/* Token box */
.tokenBox {
  padding: 60px 20px;
}

.addTokenBtn {
  width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  font-weight: 300;
  font-size: 15px;
}
.addTokenBtn div {
  margin-right: 10px;
  color: white;
}
.addTokenBtn:hover {
  cursor: pointer;
}

/* Form box */
.formBox {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: stretch;
}

/* Repo listing box */
.listingBox {
  padding: 20px 50px 0;
  width: stretch;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
.listingBox::-webkit-scrollbar {
  display: none;
}

.centerCell {
  text-align: center;
}

.searchBox {
  padding: 0 20px;
}

.smallCell {
  width: 10%;
  text-align: right;
}

.mediumCell {
  width: 30%;
  padding-right: 20px !important;
  text-align: right !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: var(--grey) !important;
}

.bigCell {
  width: 60%;
  text-overflow: ellipsis;
}

.normalRow {
  color: #000000;
  font-weight: 300;
  border: none;
}

.normalRow > td {
  border-bottom: none;
}

.normalRow:hover {
  cursor: pointer;
}

.rowSelected .bigCell {
  color: var(--main-color);
  font-weight: 500 !important;
}

/** Pagination of repos */
.paginationContainer {
  width: stretch;
  height: auto;
  background: none;
  border: none;
  transition: all 1s;
}
.pagination {
  float: right;
  max-width: 70%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.paginationIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin-left: 10px;
  background-color: #ffffff;
  color: black;
  /* box-shadow: 3px 3px 3px 3px var(--blur-shadow-color); */
  border: solid 2px white;
  transition: all linear 0.1s;
}
.paginationIndicator.disabled {
  color: var(--grey);
}
.paginationIndicator:hover:not(.disabled) {
  border: solid 2px var(--main-color);
  box-shadow: 1px 1px 5px 2px var(--btn-shadow-color);
  border-radius: 5px;
  cursor: pointer;
}

/* Success box */
.stretchDiv {
  width: stretch;
  height: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding-top: 60px;
}

.iconDiv {
  width: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: fit-content;
  padding: 10px;
}

.iconDiv img {
  width: 160px;
}

.textDiv {
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: fit-content;
  width: stretch;
  align-items: center;
  justify-content: space-around;
}

.textTitle {
  font-size: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.textContent {
  text-align: center;
  font-size: 10px;
  line-height: 15px;
}

@-moz-document url-prefix() {
  /* Form box */
  .formBox {
    width: 95%;
  }

  /* Repo listing box */
  .listingBox {
    width: 95%;
  }
  .paginationContainer {
    width: 95%;
  }

  /* Success box */
  .stretchDiv {
    width: 95%;
    height: 95%;
  }
  .iconDiv {
    width: 95%;
  }
  .textDiv {
    width: 95%;
  }
}
