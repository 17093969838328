.node {
  box-sizing: border-box;
  display: flex;
  gap: 8px;
  padding: 8px;
  border: 3px solid var(--mui-grey);
  border-radius: 8px;
  background-color: white;
  cursor: default;
  user-select: none;
}
.node[data-has-children='false'] {
  align-items: center;
  margin: -4px;
  padding: 16px 8px 16px 16px;
}

.node:hover {
  cursor: pointer;
  text-decoration: underline;
}

.handle {
  visibility: hidden;
}

.icon[data-type='dir'],
.icon[data-type='repository'] {
  color: var(--main-color);
}

.icon[data-type='file'] {
  color: #ffbb00;
}

.drag {
  margin-left: auto;
  color: var(--mui-grey);
}

.drag:hover {
  cursor: grab;
}

.container {
  display: flex;
  flex-direction: column;
}

.path {
  font-size: 12px;
  opacity: 50%;
}
